import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SearchModel } from "../../models/search.model";
import { getAuthUser } from "../../utils/auth.utils";

/**
 * Define a service using a base URL and expected endpoints to access backend api
 * @see {https://redux-toolkit.js.org/rtk-query/overview#apis}
 *  */
const authUser = getAuthUser();
export const userQueriesApi = createApi({
    reducerPath: "userQueiresApi",
    baseQuery: fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL }),
    endpoints: (builder) => ({
        getUserQueries: builder.query<SearchModel[], string>({
            query: () => `/search/searches?access_token=${authUser.token}`,
        }),
    }),
});
export const { useGetUserQueriesQuery } = userQueriesApi;
