import dayjs from "dayjs";
const localizedFormat = require("dayjs/plugin/localizedFormat");
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(localizedFormat);

export function formatDate(date: string | number | null, format = "") {
    // @ts-ignore
    const localTime = date ? dayjs.utc(date).local() : dayjs.utc().local();
    if (format) {
        return localTime.format(format);
    }
    // @ts-ignore
    return localTime.format("DD/MM/YYYY");
}

export function newUTCDate() {
    // @ts-ignore
    return dayjs().utc().format();
}
