import React, { useEffect, useState } from "react";
import Avatar from "react-avatar";
import { BiChevronDown, BiChevronUp } from "react-icons/bi";
import "../styles/LayoutHeader.component.scss";
import { formatDate } from "../utils/date.utils";
import ProfileDropdown from "./ProfileDropdown";
import { NavLink } from "react-router-dom";
import { AuthState } from "../store/slices/auth.slice";
import { useSelector } from "react-redux";

interface IHeaderProps {
    middleContent?: any;
}
const Header = (props: IHeaderProps) => {
    const authState: AuthState = useSelector((state: any) => state.auth);
    const { user: authUser } = authState;
    const [firstTimeSignIn, setFirstTimeSignIn] = useState<boolean>(true);

    useEffect(() => {
        if (window) {
            const firstTimeSignInRef = window.localStorage.getItem("firstTimeSignIn");
            if (firstTimeSignInRef) {
                setFirstTimeSignIn(false);
            } else {
                setFirstTimeSignIn(true);
                window.localStorage.setItem("firstTimeSignIn", "true");
            }
        }
    }, []);

    return (
        <header className="layout-header">
            <div className="header-leading flex items-center gap-4">
                <ProfileDropdown />
            </div>
            <div className="header-center">
                {props.middleContent ? (
                    props.middleContent
                ) : (
                    <>
                        <div className="flex items-center gap-2">
                            <NavLink to={"/"}>
                                <Avatar name="Neo AI" src="/NeoWorlder_Logo_black_Symbol_Only.png" size="18" className="logo-avatar" />
                            </NavLink>
                            <p className="welcome-date">Today,&nbsp;{formatDate("", "D MMMM YYYY")}</p>
                        </div>
                        <p className="welcome-message">
                            Welcome&nbsp;{firstTimeSignIn ? "" : "back"}, {authUser?.fullname || authUser?.username}.
                        </p>
                    </>
                )}
            </div>
            <div className="header-actions"></div>
        </header>
    );
};

export default Header;
