import React from "react";

interface Props {
    icon: React.ReactNode;
    title: string;
    feedback?: string;
    hideBorder?: boolean;
}

const ResponseFeedback: React.FC<Props> = ({
    icon,
    hideBorder = false,
    title,
    feedback,
}) => {
    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                // justifyContent: 'space-between',
                gap: "8px",
                padding: "16px 10px",
                ...(!hideBorder && { borderBottom: "1px solid #E5E5E5" }),
            }}
        >
            <div className="">{icon}</div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                <span
                    style={{
                        fontSize: "16px",
                        color: "var(--gray-900)",
                        fontWeight: 300,
                    }}
                >
                    {title}
                </span>
                <span
                    style={{
                        fontSize: "13px",
                        lineHeight: "1.3",
                        fontWeight: "200",
                    }}
                >
                    {feedback}
                </span>
            </div>
        </div>
    );
};

export default ResponseFeedback;
