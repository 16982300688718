import { Navigate } from "react-router-dom";
import { getAuthUser } from "../../utils/auth.utils";

export default function ProtectedRoute({ children }: { children: any }) {
    const user = getAuthUser();
    if (!user.token) {
        window.location.href = `${process.env.REACT_APP_NEO_AI_URL}`;
        return <></>;
    }
    return children;
}
