import { Navigate } from "react-router-dom";
import { getAuthUser } from "../../utils/auth.utils";

export default function RedirectAuth({ children }: { children: any }) {
    const user = getAuthUser();
    if (user.token) {
        return <Navigate to="/" replace />;
    }
    return children;
}
