import { IRoomParticipant, RoomGroupModel } from "../../models/room.model";
import { sortListByKey } from "../array.utils";

export function getRoomParticipantsUtil(room: RoomGroupModel): IRoomParticipant[] {
    let personaParticipants: IRoomParticipant[] = [],
        userParticipants: IRoomParticipant[] = [];

    if (room.persona_member_list) {
        personaParticipants = room.persona_member_list.map((personaMember) => ({
            id: personaMember.persona?.id as string,
            member_id: personaMember.id,
            name: personaMember.persona?.first_name + " " + personaMember.persona?.last_name,
            profile_picture: personaMember.persona?.profile_picture,
            type: "persona",
            persona_type: personaMember.persona?.type,
        }));
    }

    if (room.user_member_list) {
        userParticipants = room.user_member_list.map((userMember) => ({
            id: userMember.user?.id as string,
            member_id: userMember.id,
            name: userMember?.user.name || userMember?.user.email,
            profile_picture: userMember?.user.profile_picture,
            type: "user",
        }));
    }

    return sortListByKey<IRoomParticipant>([...(personaParticipants || []), ...userParticipants], "name");
}
