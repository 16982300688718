import axios from "axios";
import Cookies from "js-cookie";
import { AuthUserProfile, UserProfile } from "../models/user.profile";

/**
 * @description Request login
 */
export async function requestLogin(): Promise<{ url: string }> {
    const { data } = await axios.post(
        `${process.env.REACT_APP_LOOTNFT_PUBLIC_API}/api/v1/oauth/request-login`,
        {
            auth: {
                client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
                client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
            },
            data: {
                redirect_uri: `${process.env.REACT_APP_URL}/oauth_callback`,
                scope: ["profile.all"],
            },
        },
        {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/javascript",
            },
        },
    );
    // return redirect url
    return data.data;
}

/**
 *
 *
 */
export async function logOut(): Promise<any> {
    window.location.href = (process.env as any).REACT_APP_NEO_AI_URL as string;
    clearCookie("session");
}

/**
 *
 * @param token {string} Access Token
 */
export async function getProfileDetails(token: string): Promise<UserProfile> {
    const { data } = await axios.post(
        `${process.env.REACT_APP_LOOTNFT_PUBLIC_API}/api/v1/oauth/request-profile-details`,
        {
            auth: {
                access_token: token,
            },
            data: {},
        },
        {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/javascript",
            },
        },
    );
    return data.data;
}

export async function setAuthUserSession(token: string): Promise<AuthUserProfile> {
    // get user info
    const userProfile = await getProfileDetails(token);
    // session payload
    const payload = { ...userProfile, token };
    setCookie("session", JSON.stringify(payload), 30);
    // return the payload, in case caller needs it
    return payload;
}

/**
 *
 * @param accessCode Authenticate user from access code in redirect url
 */
export async function authenticateUser(accessCode: string): Promise<AuthUserProfile> {
    if (!accessCode) {
        throw new Error("Please specify a valid access code");
    }

    const { data } = await axios.post(
        `${process.env.REACT_APP_LOOTNFT_PUBLIC_API}/api/v1/oauth/request-access-token`,
        {
            auth: {
                client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
                client_secret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
            },
            data: {
                access_code: accessCode,
            },
        },
        {
            headers: {
                "Cache-Control": "no-cache",
                "Content-Type": "application/javascript",
            },
        },
    );
    // Save the token to a session
    const { token } = data.data;
    // get user info
    const userProfile = await getProfileDetails(token);
    // session payload
    const payload = { ...userProfile, token };
    setCookie("session", JSON.stringify(payload), 30);
    // return the payload, in case caller needs it
    return payload;
}

/**
 * Get Authenticated user information
 */
export function getAuthUser(): AuthUserProfile {
    try {
        return JSON.parse(getCookie("session"));
    } catch (e) {
        return {
            email: "",
            username: "",
            fullname: "",
            health_points: 0,
            token: "",
            profile_picture: "",
        };
    }
}

export function isAuthenticated(): boolean {
    const { token } = getAuthUser();
    return !!token;
}

/**
 *
 * @param name {string} The name of the Cookie
 * @param value {string} The value of the cookie
 * @param duration {number} The number of days before expiration
 */
function setCookie(name: string, value: string, duration: number) {
    const d = new Date();
    d.setTime(d.getTime() + duration * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}

function getCookie(cname: string) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(";");
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function clearCookie(name: string) {
    return (document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;");
}

/**
 *
 * @param cookieName name of the cookie to fetch
 * @param updateFunction
 */
function updateCookieJson(cookieName, updateFunction) {
    // Get the existing JSON string from the cookie
    const cookieValue = Cookies.get(cookieName);

    // Parse the JSON string into a JavaScript object
    let cookieData = {};
    if (cookieValue) {
        try {
            cookieData = JSON.parse(cookieValue);
        } catch (error) {
            console.error("Error parsing JSON from cookie:", error);
        }
    }

    // Apply the update function to modify the object
    cookieData = updateFunction(cookieData);

    // Convert the updated object back to a JSON string
    const updatedCookieValue = JSON.stringify(cookieData);

    // Update the cookie with the modified JSON string
    Cookies.set(cookieName, updatedCookieValue, { expires: 7 }); // Adjust options as needed
}

export function updateUserSession(data: AuthUserProfile) {
    // Define an update function to modify the JSON
    function updateJson(jsonData) {
        // Modify the JSON data (e.g., update keys or values)
        return { ...jsonData, ...data };
    }

    // Call the function to update the cookie JSON
    updateCookieJson("session", updateJson);

    // Now, the cookie 'myCookie' contains the updated JSON
}
