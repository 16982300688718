import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonaModel } from "../../models/persona.model";
import { BrainScanSequenceMessage, BrainScanSequenceMessageGroup, BrainScanThoughtMessage } from "../../models/brain-scan.model";
import { createSelector } from '@reduxjs/toolkit';

function getPersonaMapFromArray(personaList: PersonaModel[]): Map<string, PersonaModel> {
    return new Map(personaList.map(persona => [persona.id!, persona]));
}

function getSequenceListMapFromMessageArray(messageList: BrainScanSequenceMessage[]): Map<string, BrainScanSequenceMessageGroup[]> {
    const returnMap = new Map<string, BrainScanSequenceMessageGroup[]>();
    const collaborationIdToMessageGroupMap = new Map<string, BrainScanSequenceMessageGroup>();

    messageList.forEach(message => {
        if (message.persona_id && message.collaboration_app_id) {
            if (collaborationIdToMessageGroupMap.has(message.collaboration_app_id)) {
                collaborationIdToMessageGroupMap.get(message.collaboration_app_id)!.message_list.push(message)
            } else {
                collaborationIdToMessageGroupMap.set(message.collaboration_app_id, {
                    collaboration_app_id: message.collaboration_app_id,
                    persona_id: message.persona_id,
                    message_list: [message]
                });
            }
        }
    });

    collaborationIdToMessageGroupMap.forEach((group, collaborationAppId) => {
        if (!returnMap.has(group.persona_id)) {
            returnMap.set(group.persona_id, []);
        }
        returnMap.get(group.persona_id)!.push(group);
    });

    return returnMap;
}

function getThoughtMessageListMapFromMessageArray(messageList: BrainScanThoughtMessage[]): Map<string, BrainScanThoughtMessage[]> {
    const returnMap = new Map<string, BrainScanThoughtMessage[]>();

    messageList.forEach((message) => {
        if (message.persona) {
            if (!returnMap.has(message.persona)) {
                returnMap.set(message.persona, []);
            }
            returnMap.get(message.persona)!.push(message);
        }
    });

    return returnMap;
}

function getChainItemMapFromArray(chainList: ChainItem[]) {
    const returnMap = new Map<string, ChainItem[]>();

    chainList.forEach(item => {
        if (!returnMap.has(item.personaId)) {
            returnMap.set(item.personaId, []);
        }
        returnMap.get(item.personaId)!.push(item);
    });

    return returnMap;
}

function getPersonaAnswerIdSet(chainList: ChainItem[]) {
    return new Set<string>(
        chainList.map(item => 
            `${item.personaId}-${item.answerMessageId}`
        )
    );
}

export interface BrainScanState {
    // personaMap: Map<string, PersonaModel>;
    personaList: PersonaModel[];
    activePersonaId: string;
    thoughtMessageList: BrainScanThoughtMessage[];
    sequenceMessageList: BrainScanSequenceMessage[];
    // personaIdToSequenceListMap: Map<string, BrainScanSequenceMessageGroup[]>;
    // isBrainScanInView: boolean;
    // socketRoom: string;
    // socketConnected: boolean;
    chainItemList: ChainItem[];
}

export interface ChainItem {
    questionMessageId: string,
    answerMessageId: string,
    personaId: string,
    questionContent: string,
    answerContent: string
}

const initialState: BrainScanState = {
    // personaMap: new Map(),
    personaList: [],
    activePersonaId: "",
    // activePersonaId: "New LOT-BILL FUASHI",
    // personaIdToSequenceListMap: new Map(),
    sequenceMessageList: [],
    chainItemList: [],
    thoughtMessageList: [],
    // isBrainScanInView: true,
    // socketRoom: "",
    // socketRoom: "collaboration-app-65382619923d226b8f4c6120",
    // socketConnected: false
}

const brainScanSlice = createSlice({
    name: "brainScan",
    initialState,
    reducers: {
        setBrainScanPersonas(state, action: PayloadAction<PersonaModel[]>) {
            state.personaList = action.payload;

            // Set active Persona Immediately if not set
            if (Array.isArray(action.payload) && action.payload.length > 0) {
                if (!action.payload.find(persona => persona.id === state.activePersonaId)) {
                    state.activePersonaId = action.payload[0].id!;
                }
            } else {
                state.activePersonaId = "";
            }
        },
        setActivePersonaId(state, action: PayloadAction<string>) {
            state.activePersonaId = action.payload;
        },
        addSequenceMessage(state, action: PayloadAction<BrainScanSequenceMessage>) {
            if (action.payload.persona_id && action.payload.collaboration_app_id) {
                const messageList: BrainScanSequenceMessage[] = [...state.sequenceMessageList];
                messageList.push(action.payload);

                state.sequenceMessageList = messageList;
                // state.personaIdToSequenceListMap = getSequenceListMapFromMessageArray(messageList);
            }
        },
        clearSequence(state, action: PayloadAction<{
            persona_id: string,
            collaboration_app_id: string
        }>) {
            state.sequenceMessageList = state.sequenceMessageList.filter(message => 
                message.collaboration_app_id !== action.payload.collaboration_app_id ||
                message.persona_id !== action.payload.persona_id
            );
        },
        clearChain(state, action: PayloadAction<{
            persona_id: string
        }>) {
            state.chainItemList = state.chainItemList.filter(item => 
                item.personaId !== action.payload.persona_id
            );
        },
        addThoughtMessage(state, action: PayloadAction<BrainScanThoughtMessage>) {
            if (action.payload.persona) {
                const messageList: BrainScanThoughtMessage[] = [...state.thoughtMessageList];
                
                // If last message has the same title, just append the bodies
                let foundSameTitle = false;
                for (let i = messageList.length - 1; i >= 0 && !foundSameTitle; i--) {
                    if (messageList[i].persona === action.payload.persona) {
                        if (
                            messageList[i].title === action.payload.title
                        ) {
                            messageList[i].body += "\n\n" + action.payload.body;
                            foundSameTitle = true;
                        } else {
                            break;
                        }
                    }
                }

                if (!foundSameTitle) {
                    messageList.push(action.payload);
                }
                

                state.thoughtMessageList = messageList;
                // state.personaIdToSequenceListMap = getSequenceListMapFromMessageArray(messageList);
            }
        },
        // setSocketRoom(state, action: PayloadAction<string>) {
        //     state.socketRoom = action.payload;
        // },
        // setSocketConnected(state, action: PayloadAction<boolean>) {
        //     state.socketConnected = action.payload;
        // },
        addChainItem(state, action: PayloadAction<ChainItem>) {
            if (!state.chainItemList.find(chainItem => 
                chainItem.personaId === action.payload.personaId &&
                chainItem.answerMessageId === action.payload.answerMessageId
            )) {
                const chainItemList = [...state.chainItemList];
                chainItemList.push(action.payload);
    
                state.chainItemList = chainItemList;
            }
        },
        removeChainItem(state, action: PayloadAction<{personaId: string, answerId: string}>) {
            state.chainItemList = state.chainItemList.filter(item => 
                item.answerMessageId !== action.payload.answerId ||
                item.personaId !== action.payload.personaId    
            );
        }
    }
});

// Selectors
export const selectActivePersonaId = (state: {brainScan: BrainScanState}) => state.brainScan.activePersonaId;
export const selectActivePersona = (state: {brainScan: BrainScanState}) => {
    const activePersonaId = selectActivePersonaId(state);
    return activePersonaId ? getPersonaMapFromArray(state.brainScan.personaList).get(activePersonaId) : undefined;
};
export const selectPersonaList = (state: {brainScan: BrainScanState}) => state.brainScan.personaList;
export const selectActiveSequenceList = (state: {brainScan: BrainScanState}) => {
    const activePersonaId = selectActivePersonaId(state);
    return activePersonaId ? getSequenceListMapFromMessageArray(state.brainScan.sequenceMessageList).get(activePersonaId) : undefined;
}
export const selectActiveThoughtMessageList = (state: {brainScan: BrainScanState}) => {
    const activePersonaId = selectActivePersonaId(state);
    return activePersonaId ? getThoughtMessageListMapFromMessageArray(state.brainScan.thoughtMessageList).get(activePersonaId) : undefined;
}
export const selectActiveChainHistory = (state: {brainScan: BrainScanState}) => {
    const activePersonaId = selectActivePersonaId(state);
    return activePersonaId ? getChainItemMapFromArray(state.brainScan.chainItemList).get(activePersonaId) : undefined;
}

export const selectPersonaAnswerIdSet = createSelector(
    (state: {brainScan: BrainScanState}) => state.brainScan.chainItemList,
    (chainItemList) => getPersonaAnswerIdSet(chainItemList)
);

export const { 
    setBrainScanPersonas,
    // setSocketRoom,
    addSequenceMessage,
    addThoughtMessage,
    // setSocketConnected,
    setActivePersonaId,
    addChainItem,
    removeChainItem,
    clearSequence,
    clearChain
} = brainScanSlice.actions;

export default brainScanSlice.reducer;