import axios, { AxiosResponse } from "axios";
import { useToast } from "@chakra-ui/react";
import { getAuthUser } from "./auth.utils";
import { useDispatch } from "react-redux";
import { removeRoomFromState, setIsRoomsFetching, setRoomsState } from "../store/slices/rooms.slice";
import { populatePersonas, setPersonaLoading } from "../store/slices/persona.slice";
// import { removeGroupFromState, setGroupsState, setIsGroupsFetching } from "../store/slices/groups.slice";
import {
    clearSubscriptionData,
    setAccountCredentials,
    setAccountSubscriptionData,
    setUserAccountExists,
    setUserAccountLoading,
    setUserCreditBalance,
} from "../store/slices/account.slice";
import { updateAuthUserProfile } from "../store/slices/auth.slice";
import { setIsNotificationsFetching, setNotificationState } from "../store/slices/notification.slice";
import { FileObject } from "../models/room.model";
import {
    addPaymentMethodToState,
    deletePaymentMethodFromState,
    setPaymentDefaultPaymentMethod,
    setPaymentMethods,
} from "../store/slices/payment-method.slice";
// import { setSocketRoom } from "../store/slices/brain-scan.slice";
import hotToast from "react-hot-toast";
import dayjs from "dayjs";

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

const lootNftHttpClient = axios.create({
    baseURL: process.env.REACT_APP_LOOTNFT_PUBLIC_API,
});

interface FetchParams {
    search_criteria?: any;
    page?: number;
    limit?: number;
    sort?: any;
    populate?: any;
}

export function useHttpClient() {
    const toast = useToast();
    const { token, email } = getAuthUser();
    const dispatch = useDispatch();

    /**
     * Create room
     */
    async function createRoomApi(payload: any) {
        const { data } = await httpClient.post(`/api/v1/room/create-room?access_token=${token}`, payload);
        if (!data.success) {
            throw new Error("Cannot create room");
        }
        await listRoomsApi();

        if (data.success === false) {
            hotToast.error("Cannot create a room at this time, Please try again later");
            return null;
        }

        return data.data;
    }

    interface IListRoomOptions {
        setInState: boolean;
    }

    async function listRoomsApi(params: FetchParams = {}, options: IListRoomOptions = { setInState: true }) {
        try {
            // set data in state
            if (options.setInState) {
                dispatch(setIsRoomsFetching(true));
            }

            const { data } = await httpClient.post(`/api/v1/room/list-room?access_token=${token}`, { data: params });

            if (!data.success) {
                throw new Error("Cannot list room");
            }

            if (options.setInState) {
                dispatch(setRoomsState(data.data));

                dispatch(setIsRoomsFetching(false));
            }
            return data.data;
        } catch (e) {
            // hotToast.error("Cannot fetch your rooms at this time");
            return [];
        }
    }

    async function updateRoomApi(payload: any) {
        const { data } = await httpClient.post(`/api/v1/room/update-room?access_token=${token}`, payload);

        if (!data.success) {
            throw new Error("Cannot update room");
        }
        await listRoomsApi();
    }

    async function deleteRoomApi(id: string) {
        const { data } = await httpClient.post(`/api/v1/room/delete-room?access_token=${token}`, { id });
        // remoce id from state
        if (!data.success) {
            throw new Error("Cannot delete room");
        }
        dispatch(removeRoomFromState(id));
    }

    async function subscribeToRoomApi(roomId: string) {
        const { data } = await httpClient.post(`/api/v1/room-subscriber/create-room-subscriber?access_token=${token}`, {
            room: roomId,
            status: "subscribed",
        });

        if (data.success === false) {
            throw new Error("Cannot subscribe to room");
        }
    }

    async function listRoomSubscribersApi(roomId: string) {
        const { data } = await httpClient.post(`/api/v1/room-subscriber/list-room-subscriber?access_token=${token}`, {
            search_criteria: { room: roomId },
        });
        return data;
    }

    async function listRoomPostsApi(roomId: string) {
        const { data } = await httpClient.post(`/api/v1/post/list-post?access_token=${token}`, {
            search_criteria: { room: roomId },
            pagination: {
                rows_per_page: 50,
                page_number: 1,
            },
        });
        console.log(data);
        return data;
    }

    /**
     * Personas
     */
    async function listPersonasApi(search?: string, params: FetchParams = {}) {
        try {
            // debug
            dispatch(setPersonaLoading(true));
            const { data } = await httpClient.post(`/api/v1/persona/list-persona?access_token=${token}`, {
                data: {
                    search_criteria: search || {},
                    pagination: {
                        rows_per_page: params?.limit || 50,
                        page_number: params?.page || 1,
                    },
                    sort: params?.sort || { createdAt: 1 },
                    custom_filter: "all"
                },
            });

            if (data.success === false) {
                throw new Error("Cannot fetch personas");
            }

            // add to statePe
            dispatch(populatePersonas(data.filter(persona => persona.is_general_entity !== "yes")));
            // set loading to false
            dispatch(setPersonaLoading(false));
        } catch (e) {
            // hotToast.error("Cannot fetch your personas at this time");
        }
    }

    async function searchPersonasApi(params: { search_query: string }) {
        // CXreate a case insensitive regex
        // const regex = new RegExp(params.search_query, 'i');
        // Send API request
        const { data } = await httpClient.post("/api/v1/persona/list-persona", {
            data: { 
                search_criteria: { name: params.search_query },
                custom_filter: "all"
            },
        });

        return data;
    }

    // Group Routes

    async function searchInviteesApi(search_query: string) {
        const { data } = await httpClient.post(`/api/v1/persona-group/search-for-invitee?access_token=${token}`, {
            query: search_query,
        });

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        } else {
            return data;
        }
    }

    // async function listGroupApi(params: FetchParams = {}) {
    //     try {
    //         dispatch(setIsGroupsFetching(true));
    //         const { data } = await httpClient.post(`/api/v1/persona-group/list-persona-group?access_token=${token}`, {
    //             data: {
    //                 search_criteria: {},
    //                 populate: {
    //                     invitee_list: true,
    //                     owner: true,
    //                 },
    //             },
    //         });

    //         if (data.success) {
    //             dispatch(setGroupsState(data));
    //         }

    //         dispatch(setIsGroupsFetching(false));
    //     } catch (e) {
    //         toast({
    //             title: "Cannot fetch your rooms at this time",
    //             status: "error",
    //             description: "An error occurred while fetching rooms",
    //         });
    //     }
    // }

    // async function createGroupApi(payload: any) {
    //     const { data } = await httpClient.post(`/api/v1/persona-group/create-persona-group?access_token=${token}`, payload);

    //     if (data.success === false) {
    //         throw new Error("Something went wrong please try again later");
    //     }
    //     await listGroupApi();
    // }

    // async function updateGroupApi(payload: any) {
    //     const { data } = await httpClient.post(`/api/v1/persona-group/update-persona-group?access_token=${token}`, payload);
    //     await listGroupApi();
    // }

    // async function deleteGroupApi(id: string) {
    //     const { data } = await httpClient.post(`/api/v1/persona-group/delete-persona-group?access_token=${token}`, {
    //         id,
    //     });
    //     dispatch(removeGroupFromState(id));
    // }

    // User
    function listUsersApi(search: any, params?: any) {
        return httpClient.post(`/api/v1/user/list-user`, {
            search_criteria: search,
            pagination: {
                rows_per_page: params?.limit || 50,
                page_number: params?.page || 1,
            },
            sort: params?.sort || { createdAt: 1 },
        });
    }
    /**
     *  ================ FETCH USER CREDENTIALS =========
     */
    async function fetchUserCredentialsApi(userEmail = email) {
        dispatch(setUserAccountLoading(true));
        const { data } = await listUsersApi({ email: userEmail }, { page: 1, limit: 1 });
        const user = data?.find((user) => user.email === userEmail);

        if (user) {
            dispatch(setUserAccountExists(true));
            const authUser = getAuthUser();
            // Remove sensitive keys
            user.pinecone_settings = null;
            user.openai_settings = null;

            dispatch(
                setAccountCredentials({
                    ...user,
                    id: user.id,
                    email: user.email,
                    name: authUser.fullname as string,
                    username: authUser.username as string,
                }),
            );
        }
        dispatch(setUserAccountLoading(false));
    }

    async function updateUserDetailsApi(payload: any) {
        try {
            const { data } = await httpClient.post(
                `/api/v1/user/update-user-profile-details?access_token=${token}`,
                payload,
            );
            if (data.success === false) {
                hotToast.error("We cannot update your profile at this time, Try again later");
                return;
            }
            // update user profile
            dispatch(updateAuthUserProfile(payload));
        } catch (e) {
            hotToast.error("We cannot update your profile at this time, Try again later");
        }
    }

    async function updateUserProfilePictureApi(file: File) {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const { data } = await lootNftHttpClient.post(`/api/v1/user/add-profile-picture`, formData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    app_token: "",
                    "Content-Type": "multipart/form-data",
                },
            });

            if (data.success === false) {
                throw new Error("Something went wrong please try again later");
            }
            // update user profile picture
            const profile_picture = data.data.profile_picture_url;
            dispatch(updateAuthUserProfile({ profile_picture }));
        } catch (error) {
            console.log(error);
        }
    }

    /***
     * Save user's info in the database...
     */
    async function createUserApi(payload) {
        return httpClient.post(`/api/v1/user/create-user?access_token=${token}`, {
            email: payload.email,
            openai_settings: {
                api_key: payload.openAiKey,
            },
            pinecone_settings: {
                api_key: payload.pineconeKey,
                env: payload.pineconeIndex,
                index: payload.pineconeIndex,
            },
        });
    }
    interface ListRoomMessageSearchCriteria {
        room: string;
        id?: string;
        parent_id?: string;
        collaboration_app?: any;
        or?: any;
    }

    // Messages
    async function listRoomMessageApi(searchCriteria: ListRoomMessageSearchCriteria) {
        const { data } = await httpClient.post(`/api/v1/room-message/list-room-message?access_token=${token}`, {
            data: {
                search_criteria: searchCriteria,
                populate: { user: true, file_object: true },
            },
        });

        if (data.success === false) {
            throw new Error("Could not list messages ");
        } else {
            const { data: historyData } = await httpClient.post(
                `/api/v1/room-history/list-room-history?access_token=${token}`,
                {
                    data: {
                        search_criteria: { message_id: data.map((msg) => msg.id) },
                    },
                },
            );

            if (historyData.success === false) {
                throw new Error("Could not list messages ");
            } else {
                let messageData = [];

                messageData = data.map((msg) => {
                    if (Array.isArray(historyData)) {
                        const historyRecordIndex = historyData.findIndex(
                            (history: any) => history.message_id === msg.id,
                        );
                        if (historyRecordIndex > -1) {
                            msg.isRated = true;
                        }
                    }
                    return msg;
                });

                return messageData;
            }
        }
    }

    async function createRoomMessageApi(payload: any) {
        const { data } = await httpClient.post(
            `/api/v1/room-message/create-room-message?access_token=${token}`,
            payload,
        );

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        }
        return data;
    }

    async function addVideoToRoomMessageApi(blob: Blob, filename: string = "recorded-video.webm") {
        // Convert the blob to a File
        const file = new File([blob], filename, {
            type: "video/webm",
        });

        const formData = new FormData();
        formData.append("file", file);

        try {
            const { data } = await httpClient.post(`/api/v1/room-message/add-video?access_token=${token}`, formData);
            return data;
        } catch (error) {
            console.log("~ file: http.utils.ts:313 ~ addVideoToRoomMessageApi ~ error:", error);
        }
    }

    async function addAudioToRoomMessageApi(blob: Blob) {
        try {
            // Convert the blob to a File
            const file = new File([blob], "recorded-audio.mp3", {
                type: "audio/mp3",
            });

            const formData = new FormData();
            formData.append("file", file);
            // Send API Rquest
            const { data } = await httpClient.post(`/api/v1/room-message/add-audio?access_token=${token}`, formData);
            return data;
        } catch (error) {
            console.log("~ file: http.utils.ts:313 ~ addAudioToRoomMessageApi ~ error:", error);
        }
    }

    async function addMessageToHistoryApi({ message, personaId, userId, messageId }) {
        try {
            const { data } = await httpClient.post(`/api/v1/room-history/create-room-history?access_token=${token}`, {
                data: {
                    content: message,
                    message_id: messageId,
                    user: userId,
                    persona: personaId,
                },
            });
            console.log(data);
            if (data.success === false) {
                if (data.error?.length > 0) {
                    throw new Error(data.error.map((err: any) => err.message).join(", "));
                }
                throw new Error("Something went wrong please try again later");
            }
            return data;
        } catch {
            toast({
                title: "Could not rate message",
                status: "error",
                description: "An error occurred while rating message",
            });
            return false;
        }
    }

    async function listRoomHistoryApi(messageId: string | string[]) {
        const { data } = await httpClient.post(`/api/v1/room-history/list-room-history?access_token=${token}`, {
            search_criteria: { message_id: messageId },
        });
        return data;
    }

    // Notifications
    async function listNotificationApi(params: FetchParams = {}) {
        try {
            dispatch(setIsNotificationsFetching(true));
            const { data } = await httpClient.post(
                `/api/v1/persona-room/list-room-notification?access_token=${token}`,
                {
                    data: {
                        search_criteria: {},
                        populate: {
                            invitee_list: true,
                            owner: true,
                        },
                    },
                },
            );

            dispatch(setNotificationState(data));

            dispatch(setIsNotificationsFetching(false));
        } catch (e) {
            toast({
                title: "Cannot fetch your notification at this time",
                status: "error",
                description: "An error occurred while fetching rooms",
            });
        }
    }

    async function createNotificationApi(payload: any) {
        const { data } = await httpClient.post(
            `/api/v1/persona-group/create-notification?access_token=${token}`,
            payload,
        );

        if (data.success === false) {
            throw new Error("Something went wrong please try again later");
        }
    }

    async function updateNotificationApi(payload: any) {
        const { data } = await httpClient.post(
            `/api/v1/persona-group/update-notification?access_token=${token}`,
            payload,
        );
    }

    /**
     *
     * =============== Collaboration Flow Section ================
     */

    // async function initializeCollaborationCommunicationApi(persona: PersonaModel, query: string) {
    //     try {
    //         // dispatch(setIsAutoInteractionInitializing(true));
    //         const { data } = await httpClient.post(`/api/v1/collaboration-app/initialize-communication?access_token=${token}`, {
    //             persona: persona.id,
    //         });

    //         if (data.success === false) {
    //             throw new Error("Cannot init");
    //         }
    //         // console.log(data);
    //         dispatch(setSocketRoom(data.socket));
    //         // dispatch(setIsAutoInteractionInitialized(true));
    //         // dispatch(setCollaborationAppId(data["collaboration_app"].id));
    //         // dispatch(
    //         //     setCollaborateInitializationMessage(
    //         //         data["initialization_message"],
    //         //     ),
    //         // );

    //         // dispatch(setCollaborationPersona(persona));
    //         // dispatch(setAutoInteractionId(data["auto_interaction_id"]));
    //         // dispatch(
    //         //     addAutoInteractionMessage({
    //         //         message_format: "normal",
    //         //         message_type: "ai",
    //         //         message: data["initialization_message"],
    //         //     }),
    //         // );
    //     } catch (e) {
    //         console.error(e);
    //         toast({
    //             title: "Could not initialize collaboration",
    //             description: "Please try again later",
    //             status: "error",
    //             duration: 2500,
    //         });
    //     }
    // }

    /**
     *  =================  SAVING TO FOLDER ================
     */
    async function saveToFolderApi(searchId: string, folderName: string) {
        try {
            await httpClient.put(`/search/folder/${searchId}?access_token=${token}`, {
                folderName,
            });
            // Add folder to current state, in the case of creating a folder
            // The state action will handle filtering duplicates
            // dispatch(addFolder({ name: folderName }));
        } catch (e) {
            console.error(e);
            toast({
                title: "There was an error saving your query, Please try again later",
                status: "error",
                duration: 2500,
            });
        }
    }

    /**
     * ======================= File API ===========================
     */
    async function listFileApi(searchCriteria: { room: string }): Promise<FileObject[]> {
        const { data } = await httpClient.post(`/api/v1/file-object/list-file-object?access_token=${token}`, {
            data: {
                search_criteria: searchCriteria,
                sort: {
                    createdAt: -1,
                },
            },
        });

        if (!data.success) {
            throw new Error("Could not list messages ");
        } else {
            return data.data;
        }
    }

    /**
     * ====== PAYMENT METHOD ===============
     */
    async function createPaymentMethodApi(payload) {
        const { data } = await httpClient.post(
            `/api/v1/payment-method/create-payment-method?access_token=${token}`,
            payload,
        );
        if (data.success === false) {
            toast({
                title: "There was an error creating your payment method, Please try again later",
                status: "error",
                duration: 2500,
            });
            return null;
        } else {
            // show toast
            toast({
                title: "Payment method added.",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
            // add data to state
            dispatch(
                addPaymentMethodToState({
                    id: data.payment_method_id,
                    ...payload,
                }),
            );
            return data;
        }
    }

    async function listPaymentMethodApi(params: FetchParams = {}) {
        const { data } = await httpClient.post(`/api/v1/payment-method/list-payment-method?access_token=${token}`, {
            data: params,
        });
        if (data.success === false) {
            // toast({
            //     title: "There was an error fetching your payment methods, Please try again later",
            //     status: "error",
            //     duration: 2500,
            // });
            return null;
        } else {
            dispatch(setPaymentMethods(data));
            return data;
        }
    }

    async function updatePaymentMethodApi(paymentMethodId: string, payload) {
        const { data } = await httpClient.post(`/api/v1/payment-method/update-payment-method?access_token=${token}`, {
            data: {
                search_criteria: { id: paymentMethodId },
                update_params: payload,
            },
        });
        if (data.success === false) {
            // toast({
            //     title: "There was an error updating your payment method, Please try again later",
            //     status: "error",
            //     duration: 2500,
            // });
            throw new Error("Failed to update payment method");
        } else {
            // update state
            dispatch(setPaymentDefaultPaymentMethod(paymentMethodId));
            return data;
        }
    }

    async function deletePaymentMethodApi(paymentMethodId: string) {
        const { data } = await httpClient.post(`/api/v1/payment-method/delete-payment-method?access_token=${token}`, {
            id: paymentMethodId,
        });
        if (data.success === false) {
            toast({
                title: "There was an error deleting your payment method, Please try again later",
                status: "error",
                duration: 2500,
            });
            return null;
        } else {
            dispatch(deletePaymentMethodFromState(paymentMethodId));
            return data;
        }
    }

    async function purchaseCreditsApi(payload) {
        const { data } = await httpClient.post(`/api/v1/payment-charge/create-payment-charge?access_token=${token}`, {
            data: {
                ...payload,
                payment_motive: "purchase_credit"
            },
        });

        if (data.success === false) {
            throw new Error(data?.error?.[0]?.message);
        }
        /**
         * Fetch new credit balance
         * !import note: do no await this promise because we do not want it to interrupt the data being return 
         * incase it fails. the data return does not depend on the promise getting resolved. 
         * And don not worry about handle errors from the promise, it is already handle in the function definition.
         * This is fetch to display the new credit balance in the UI
         */
        listCreditBalanceApi();
        return data;
    }

    /**
     * List subscriptions prices
     */
    async function listSubscriptionPricesApi() {
        const { data } = await httpClient.post(
            `/api/v1/subscription-price/list-subscription-price?access_token=${token}`,
            {
                data: {
                    search_criteria: {
                        product: process.env.REACT_APP_STRIPE_PRODUCT_ID as string,
                    },
                },
            },
        );
        if (data.success === false) {
            toast({
                title: "There was an error fetching the subscription prices, Please try again later",
                status: "error",
                duration: 2500,
            });
            return null;
        } else {
            return data.data;
        }
    }

    /**
     * Create stripe checkout session
     */
    async function createSubscriptionSessionApi(payload: any) {
        const success_url = `${process.env.REACT_APP_URL}/payments/callback/subscription-success`,
            cancel_url = `${process.env.REACT_APP_URL}/payments/callback/subscription-cancelled`;

        const { data } = await httpClient.post(
            `/api/v1/subscription-session/create-subscription-session?access_token=${token}`,
            {
                data: {
                    subscription_price: payload.subscription_price,
                    success_url: success_url,
                    cancel_url: cancel_url,
                },
            },
        );
        if (data.success === false) {
            toast({
                title: "There was an error creating the subscription session, Please try again later",
                status: "error",
                duration: 2500,
            });
            return null;
        } else {
            return data;
        }
    }

    /**
     * list user organization
     */
    async function listUserOrganizationApi() {
        let organizations = [];
        try {
            const { data } = await httpClient.post(
                `/api/v1/organization/list-user-organization?access_token=${token}`,
                {
                    data: {},
                },
            );

            if (data.success === false) {
                throw new Error("Cannot fetch organizations");
            } else {
                organizations = data;
            }
        } catch (e) {
            hotToast.error("We are unable to fetch your organizations at this time.");
        }
        return organizations;
    }

    /**
     * Subscription
     */
    async function listUserSubscriptionApi() {
        try {
            const { data } = await httpClient.post(`/api/v1/subscription/list-subscription?access_token=${token}`, {
                data: {
                    data: {
                        // This is removed because the backend subscription is application on the UUI
                        // search_criteria: {
                        //     product: process.env.REACT_APP_STRIPE_PRODUCT_ID as string,
                        // },
                    },
                },
            });

            if (data.success === false) {
                // hotToast.error(data?.error?.[0]?.message);
                return;
            }
            // add subscription data to state
            // console.log(data)
            dispatch(setAccountSubscriptionData(data[0]));
            // return data...
            return data;
        } catch (e) {
            hotToast.error("Cannot fetch your subscription data at this time, Please try again");
            return null;
        }
    }

    async function cancelUserSubscriptionApi(id: string) {
        try {
            const { data } = await httpClient.post(`/api/v1/subscription/cancel-subscription?access_token=${token}`, {
                data: {
                    id,
                },
            });
            if (data.success === false) {
                hotToast.error(data?.error?.[0]?.message);
                return;
            }
            dispatch(clearSubscriptionData());

        } catch (error) {
            hotToast.error("Cannot cancel subscription at this time");
        }
    }

    async function listUserTransactionsApi() {
        try {
            const { data } = await lootNftHttpClient.post(`/api/v1/transaction/list-user-transaction`, {
                data: {
                    search_criteria: {
                        transaction_type: ["increase_word_credit_balance", "increase_forwardable_word_credit_balance"],
                    },
                },
                auth: {
                    access_token: token,
                },
            });

            if (data.success === false) {
                hotToast.error("Cannot display transactions. Please try again later.");
                return [];
            }
            // update user profile picture
            return data.data;
        } catch (error) {
            hotToast.error("Cannot display transactions. Please try again later.");
            return [];
        }
    }

    async function listCreditBalanceApi() {
        try {
            const { data } = await lootNftHttpClient.post("/api/v1/user/get-user-balance", {
                data: {
                    // search_criteria: {
                    //     transaction_type: ["increase_word_credit_balance", "increase_forwardable_word_credit_balance"],
                    // },
                },
                auth: {
                    access_token: token,
                },
            });

            if (data.success === false) {
                // TODO: DISPLAY CUSTOM ERROR MESSAGE
                hotToast.error("Cannot diplay your credit balance at this time");
            }
            const balance = Number(data.data.word_credit_balance || 0) + Number(data.data.forwardable_word_credit_balance || 0);
            // setstate data
            dispatch(setUserCreditBalance(Number(balance.toFixed(4))))
        } catch (e) {
            console.error(e);
            hotToast.error("Cannot diplay your credit balance at this time");
        }
    }

    async function listCreditUsageApi(personaId?: string) {
        try {
            const { data } = await lootNftHttpClient.post(`/api/v1/transaction/list-user-transaction`, {
                auth: {
                    access_token: token,
                },
                data: {
                    ...(personaId && { external_entity_id: personaId }),
                    search_criteria: {
                        transaction_type: "decrease_word_credit_balance",
                        createdAt: {
                            ">=": dayjs().startOf("day").subtract(6, "days").valueOf(),
                        },
                    },
                    sort: {
                        createdAt: -1,
                    },
                },
            });

            if (data.success) {
                return data.data;
            }
        } catch (e) { }

        // toast({
        //     title: "There was an error getting persona transactions.",
        //     status: "error",
        //     duration: 2500,
        // });
        return [];
    }

    async function sendExecutionCommandApi(collaborationAppId: string, response_type: "execute_next_cell" | "execute_previous_cell" | "execute_tool" | "cancel_execute_tool") {
        try {
            const { data } = await httpClient.post(`/api/v1/collaboration-app/send-execution-command?access_token=${token}`, {
                collaboration_app_id: collaborationAppId,
                response_type,
            });

            if (data.success === false) {
                console.error(data.error);
                toast({
                    title: "Could not send message to AI in collaboration",
                });
            }
        } catch (e) {
            console.error(e);
            toast({
                title: "Could not send message to AI in collaboration",
            });
        }
    }

    async function listOrganizationMemberApi(params: FetchParams = {}) {
        const { data } = await httpClient.post(`/api/v1/organization-member/list-organization-member?access_token=${token}`, {
            data: {
                ...params,
                pagination: {
                    rows_per_page: params.limit || 50,
                    page_number: params.page || 1,
                },
                sort: {
                    createdAt: 1,
                },
            },
        });
        if (data.success === false) {
            toast({
                title: "There was an error fetching your organization members, Please try again later",
                status: "error",
                duration: 2500,
            });
            throw new Error("Failed to fetch organization members");
        } else {
            return data;
        }
    }

    async function acceptOrganizationMemberInvitationApi(organizationId: string, accept: "yes" | "no") {
        const { data } = await httpClient.post(`/api/v1/organization-member/accept-organization-member?access_token=${token}`, {
            data: {
                organization: organizationId,
                accept
            }
        });

        if (data.success === false) {
            throw new Error(data?.error?.[0]?.message);
        }
    }

    // Add a request interceptor
    httpClient.interceptors.response.use(
        (response) => {
            return response;
        },
        (error) => {
            switch (error.status) {
                case 401:
                    // TODO: SHOW THE USER AN UNAUTHORIZED MODAL
                    toast({
                        title: "Access denied!",
                        status: "warning",
                        description: "You were not authorized to carry out this request, Please refresh the page",
                        duration: 3000,
                    });
                    // setTimeout(() => location.reload(), 3000);
                    break;
                case 403:
                    // TODO: SHOW THE USER A ACCESS DENIED MODAL
                    break;
                case 410:
                    // TODO LOG OUT THE USER AND SHOW A DISABLE MESSAGE
                    break;
            }
            throw error;
        },
    );

    return {
        createRoomApi,
        listRoomsApi,
        updateRoomApi,
        listPersonasApi,
        searchPersonasApi,
        deleteRoomApi,
        subscribeToRoomApi,
        listRoomSubscribersApi,
        listRoomPostsApi,
        searchInviteesApi,
        // listGroupApi,
        // createGroupApi,
        // updateGroupApi,
        // deleteGroupApi,
        listUsersApi,
        fetchUserCredentialsApi,
        updateUserDetailsApi,
        updateUserProfilePictureApi,
        listRoomMessageApi,
        createRoomMessageApi,
        addVideoToRoomMessageApi,
        addAudioToRoomMessageApi,
        addMessageToHistoryApi,
        listRoomHistoryApi,
        listNotificationApi,
        createNotificationApi,
        updateNotificationApi,
        // initializeCollaborationCommunicationApi,
        saveToFolderApi,
        listFileApi,
        createPaymentMethodApi,
        listPaymentMethodApi,
        updatePaymentMethodApi,
        deletePaymentMethodApi,
        listSubscriptionPricesApi,
        createSubscriptionSessionApi,
        purchaseCreditsApi,
        listUserOrganizationApi,
        listUserSubscriptionApi,
        cancelUserSubscriptionApi,
        listUserTransactionsApi,
        listCreditBalanceApi,
        listCreditUsageApi,
        sendExecutionCommandApi,
        createUserApi,
        listOrganizationMemberApi,
        acceptOrganizationMemberInvitationApi
    };
}
