import { configureStore } from "@reduxjs/toolkit";
import folderReducer from "./slices/folder.slice";
import searchesReducer from "./slices/searches.slice";
import userQueriesReducer from "./slices/user-queries.slice";
import uploadedDataReducer from "./slices/memories.slice";
import authReducer from "./slices/auth.slice";
import threadReducer from "./slices/threads.slice";
import accountReducer from "./slices/account.slice";
import collaborateReducer from "./slices/collaborate.slice";
import chatReducer from "./slices/chat.slice";
import personaReducer from "./slices/persona.slice";
import roomsReducer from "./slices/rooms.slice";
// import groupsReducer from "./slices/groups.slice";
import notificationsReducer from "./slices/notification.slice";
import messagesReducer from "./slices/message.slice";
import threadMessagesReducer from "./slices/threads.slice";
import brainScanReducer from "./slices/brain-scan.slice";
import paymentMethodReducer from "./slices/payment-method.slice";
import { userQueriesApi } from "./services/userQueries.service";

export default configureStore({
    reducer: {
        folders: folderReducer,
        collaborate: collaborateReducer,
        personas: personaReducer,
        searches: searchesReducer,
        userQueries: userQueriesReducer,
        uploadedData: uploadedDataReducer,
        auth: authReducer,
        account: accountReducer,
        threads: threadReducer,
        chat: chatReducer,
        rooms: roomsReducer,
        // groups: groupsReducer,
        notification: notificationsReducer,
        messages: messagesReducer,
        threadMessages: threadMessagesReducer,
        brainScan: brainScanReducer,
        paymentMethod: paymentMethodReducer,
        [userQueriesApi.reducerPath]: userQueriesApi.reducer,
    },
    // Adding the api middleware enables caching, invalidation, polling,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([userQueriesApi.middleware]),
});
