import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useHttpClient } from "../utils/http.utils";
import { AccountState } from "../store/slices/account.slice";
import { ROUTES } from "../routes";

export default function OpenPersonaPortalView() {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const { createRoomApi, listRoomsApi } = useHttpClient();
    const { data: userData }: AccountState = useSelector((state: any) => state.account);
    const personaId = searchParams.get("pid"),
        personaName = searchParams.get("pname");
    const [validationError, setValidationError] = useState<string>();

    useEffect(() => {
        // validate params
        if (!personaId && !personaName) {
            setValidationError("The portal link seems to broken, make sure you have the right access to the persona.");
        } else if (userData?.id) {
            loadPortal();
        }
    }, [userData]);

    const loadPortal = async () => {
        /**
         * First fetch if a portal between the user and the person exist
         * Second, Create the portal
         */
        const data = await listRoomsApi(
            {
                search_criteria: {
                    owner: userData.id,
                    is_single: "yes",
                },
            },
            { setInState: false },
        );

        const room = data.find((room) => room.persona_member_list[0].persona.id === personaId);
        if (room) {
            // navigate to room
            navigate(ROUTES.ROOM_CHAT.replace(":id", room.id));
        } else {
            const roomResponse = await createRoomApi({
                title: personaName,
                description: `Portal with ${personaName}`,
                is_single: "yes",
                persona_list: [personaId],
            });

            if (roomResponse) {
                // navigate to room
                navigate(ROUTES.ROOM_CHAT.replace(":id", roomResponse.id));
            }
        }
    };

    return (
        <div className="bg-gradient-to-br from-gray-50 via-gray-100 to-gray-300 flex relative h-screen w-screen justify-center items-center">
            <img
                src="/neo_ai_logo_black.png"
                alt=""
                className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]"
            />
            <div className="oauth-content text-center">
                <h1 className="text-3xl font-semibold">
                    {validationError ? "Oh Uh! Something is not right.." : "Hold on, while we open your portal.."}
                </h1>
                {validationError ? (
                    <div className="w-[400px] mx-auto mt-6 min-h-[50px] rounded-[8px] border border-red-400 bg-red-50 px-4 py-3 text-red-900">
                        {validationError}
                    </div>
                ) : (
                    <div className="mt-4">
                        <Spinner size="xl" />
                    </div>
                )}
            </div>
        </div>
    );
}
