// listUserOrganizationApi
import {
    Button,
    ButtonGroup,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useHttpClient } from "../../utils/http.utils";
import { MdOutlineLocationOn } from "react-icons/md";
import { FaBuilding } from "react-icons/fa";
import hotToast from "react-hot-toast";

interface NavigateBackendModalProps {
    isOpen: boolean;
    authUserToken?: string;
    onClose: () => void;
}

const NavigateBackendModal: React.FC<NavigateBackendModalProps> = (props) => {
    const modalDis = useDisclosure();
    const { listUserOrganizationApi, listOrganizationMemberApi, acceptOrganizationMemberInvitationApi } = useHttpClient();
    const [organizations, setOrganizations] = useState([]);
    const [organizationInvitations, setOrganizationInvitations] = useState<any[]>([]);
    const [isInvitationLoading, setIsInvitationLoading] = useState<boolean>(false)

    useEffect(() => {
        loadOrganizations();
    }, []);

    useEffect(() => {
        if (props.isOpen) {
            modalDis.onOpen();
        } else {
            modalDis.onClose();
        }
    }, [props.isOpen]);

    const loadOrganizations = async () => {
        try {
            if (organizations.length == 0) {
                const data = await listUserOrganizationApi();
                setOrganizations(data);
            }
            // fetch organizations
            const invitations = await listOrganizationMemberApi({
                search_criteria: {
                    status: "invited",
                },
                populate: {
                    organization: true
                }
            });
            setOrganizationInvitations(invitations);
        } catch (e) {
            console.log(e);
            hotToast.error("Cannot load your organizations");
        }
    };

    const closeViewModal = () => {
        modalDis.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const openBackend = (organizationId: string) => {
        if (props.authUserToken) {
            // @ts-ignore
            window.open(`${process.env.REACT_APP_NEO_AI_URL}/oauth_callback?access_token=${props.authUserToken}&org=${organizationId}`, "_blank").focus();
            closeViewModal();
        }
    };

    const acceptInvitation = async (invitationObj: any, accept: "yes" | "no") => {
        setIsInvitationLoading(true);
        hotToast.promise(acceptOrganizationMemberInvitationApi(invitationObj.organization?.id, accept), {
            error: () => {
                setIsInvitationLoading(false);
                return "Cannot accept invitation at this time, Please try again later"
            },
            success: () => {
                setIsInvitationLoading(false);
                // load the user's organizations
                if (accept === "yes") {
                    loadOrganizations();
                }
                // remove object from state
                setOrganizationInvitations((prevState) => prevState.filter((inv) => inv.id !== invitationObj.id));
                return "You invitation was accepted";
            },
            loading: accept === "yes" ? "Accepting invitation.." : "Declining invitation.."
        })
    }

    return (
        <Modal size="lg" isOpen={modalDis.isOpen} onClose={closeViewModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Choose Organization</ModalHeader>
                <ModalBody pt={0}>
                    <div className="min-h-[300px]">
                        <div className="min-h-auto">
                            {organizations.map((org: any, index) => (
                                <div
                                    onClick={() => openBackend(org.id)}
                                    key={"org" + index}
                                    aria-label="button"
                                    className="org border px-3 border-white cursor-pointer hover:bg-gray-50 hover:border-gray-300 hover:rounded-[8px] py-3 flex gap-[12px]"
                                >
                                    <div className="icon">
                                        <div className="h-[45px] w-[45px] bg-gray-400 rounded-[8px] flex justify-center items-center">
                                            <FaBuilding size="25px" className="text-white" />
                                        </div>
                                    </div>
                                    <div className="details">
                                        <h2 className="text-[16px]">{org?.name}</h2>
                                        <h4 className="text-[13px] gap-[0px] flex items-center text-slate-400">
                                            <MdOutlineLocationOn size="15px" />
                                            {org?.address?.city}, {org?.address?.country}
                                        </h4>
                                    </div>
                                </div>
                            ))}
                        </div>
                        {organizationInvitations.length > 0 && (<div className="min-h-[100px]">
                            <h2 className="text-[16px] mb-[5px]">Invitations ({organizationInvitations.length})</h2>
                            {organizationInvitations.map((invitation: any, index) => (
                                <div
                                    key={"org" + index}
                                    aria-label="button"
                                    className="border px-3 border-white cursor-pointe hover:bg-gray-50 hover:border-gray-300 hover:rounded-[8px] py-3 flex gap-[12px] justify-between items-center"
                                >
                                    <div className="flex gap-[10px] items-center">
                                        <div className="icon">
                                            <div className="h-[45px] w-[45px] bg-gray-400 rounded-[8px] flex justify-center items-center">
                                                <FaBuilding size="25px" className="text-white" />
                                            </div>
                                        </div>
                                        <div className="details">
                                            <h2 className="text-[16px] leading-[1]">{invitation.organization?.name}</h2>
                                            <h4 className="text-[13px] gap-[0px] flex items-center text-slate-400">
                                                {invitation.role}
                                            </h4>
                                        </div>
                                    </div>
                                    <ButtonGroup>
                                        <Button variant="ghost" size="sm" isDisabled={isInvitationLoading} colorScheme="blue" onClick={() => acceptInvitation(invitation, "yes")}>Accept</Button>
                                        <Button variant="ghost" size="sm" isDisabled={isInvitationLoading} colorScheme="red" onClick={() => acceptInvitation(invitation, "no")}>Reject</Button>
                                    </ButtonGroup>
                                </div>
                            ))}
                        </div>)}
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default NavigateBackendModal;
