import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Spinner } from "@chakra-ui/react";
import { setAuthUserSession } from "../../utils/auth.utils";
import { useDispatch } from "react-redux";
import { setAuthState, setAuthUser } from "../../store/slices/auth.slice";
import { useHttpClient } from "../../utils/http.utils";

export default function OAuthCallback() {
    let [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { listUsersApi, createUserApi } = useHttpClient();
    const [validationError, setValidationError] = useState<string>();
    const initialized = useRef(false);

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true

            const accessToken = searchParams.get("access_token") as string;
            const createUserIfNotExists = async (email: string) => {
                const { data } = await listUsersApi({ email }, { page: 1, limit: 1 });

                if (data && data.length < 1) {
                    await createUserApi({email});
                }
            };

            if (accessToken) {
                setAuthUserSession(accessToken).then((user) => {
                    dispatch(setAuthUser(user));
                    dispatch(setAuthState(true));

                    // Create user if not exists
                    createUserIfNotExists(user.email!)
                        .finally(() => {
                            // navigate to landing page
                            navigate("/");
                        });
                });
            } else {
                setValidationError("We cannot sign you in at the moment, the authorization link seems too be broken. Please login again.");
            }
        }
    }, []);

    return (
        <div className="bg-gradient-to-br from-gray-50 via-gray-100 to-gray-300 flex h-screen w-screen justify-center items-center">
            <img
                src="/neo_ai_logo_black.png"
                alt=""
                className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]"
            />
            <div className="oauth-content text-center">
                <h1 className="text-3xl font-semibold">
                    {validationError ? "Oh Uh! Something is not right.." : "Hold on, while we authenticate you.."}
                </h1>
                {validationError ? (
                    <div className="w-[400px] mx-auto mt-6 min-h-[50px] rounded-[8px] border border-red-400 bg-red-50 px-4 py-3 text-red-900">
                        {validationError}
                    </div>
                ) : (
                    <div className="mt-4">
                        <Spinner size="xl" />
                    </div>
                )}
            </div>
        </div>
    );
}
