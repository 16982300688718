import React, { useState } from "react";
import "../styles/LogoAndProfile.component.scss";
import { LordIcon } from "./icons/LordIcon";
import { useToast } from "@chakra-ui/react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { AuthState } from "../store/slices/auth.slice";
import { useSelector } from "react-redux";
import { logOut } from "../utils/auth.utils";
import PaymentMethodsModal from "./PaymentMethodsModal";
import ProfileModal from "./account/ProfileModal";
import CreditsModal from "./account/CreditsModal";
import NavigateBackendModal from "./account/NavigateBackendModal";
import { AccountState } from "../store/slices/account.slice";
import { formatCurrency } from "../utils/number.utils";

interface ProfileDropdownProps {
    dropDownStyle?: React.CSSProperties;
}

const ProfileDropdown: React.FC<ProfileDropdownProps> = (props: ProfileDropdownProps) => {
    const avatarRef = React.useRef(null);
    const accountState: AccountState = useSelector((state: any) => state.account);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showProfileModal, setShowProfileModal] = useState(false);
    const authState: AuthState = useSelector((state: any) => state.auth);
    const { user: authUser } = authState;
    const [showPaymentMethodView, setShowPaymentMethodView] = useState(false);
    const [showCreditsModal, setShowCreditsModal] = useState(false);
    const [showOrganizationModal, setShowOrganizationModal] = useState(false);

    const navigateToBackend = () => {
        // show Modal to loadbackends
        // @ts-ignore
        // window
        //     .open(`${process.env.REACT_APP_NEO_AI_URL}/oauth_callback?access_token=${authUser?.token}`, "_blank")
        //     .focus();
        setShowOrganizationModal(true);
    };

    function logoutUser() {
        logOut();
    }

    return (
        <div className="logo-profile flex items-center gap-4">
            <div className="profile-dropdown">
                <img
                    ref={avatarRef}
                    onClick={() => setShowProfileModal(true)}
                    className="profile-image cursor-pointer"
                    src={authUser?.profile_picture || "/user_avatar.png"}
                    onError={(error) => {
                        (avatarRef.current as any).src = "/user_avatar.png";
                    }}
                    alt="profile"
                />
                <div onClick={() => setShowDropdown(!showDropdown)} className="name-with-arrow">
                    <span className="name-text">{authUser?.fullname || authUser?.username}</span>
                    <span className="dropdown-icon">
                        {showDropdown ? (
                            // lord-icons
                            <LordIcon
                                src="/lord-icons/chevron-up.json"
                                trigger="hover"
                                colors={{ primary: "#121331" }}
                                size={16}
                            />
                        ) : (
                            <LordIcon
                                src="/lord-icons/chevron-down.json"
                                trigger="hover"
                                colors={{ primary: "#121331" }}
                                size={16}
                            />
                        )}
                    </span>
                </div>
                {showDropdown && (
                    <div
                        style={props.dropDownStyle}
                        className={`dropdown shadow ${showDropdown ? "show-dropdown" : ""}`}
                    >
                        <div onClick={navigateToBackend} className="dropdown-tile relative flex items-center gap-1.5">
                            <LordIcon
                                src="/lord-icons/entities.json"
                                trigger="hover"
                                colors={{
                                    primary: "#121331",
                                    secondary: "#333",
                                }}
                                size={30}
                            />
                            <p className="dropdown-title text" style={{ marginBottom: "4px" }}>
                                Grow AI Entities
                            </p>
                        </div>
                        <div className="user-data">
                            <div onClick={() => setShowProfileModal(true)} className="dropdown-item">
                                <span className="dropdown-title">{authUser?.username}</span>
                                <span className="dropdown-subtitle">username</span>
                            </div>

                            <div onClick={() => setShowCreditsModal(true)} className="dropdown-item">
                                <p className="dropdown-title">
                                    <span className="">T{formatCurrency(accountState.creditBalance)}</span>
                                </p>
                                <span className="dropdown-subtitle">credit</span>
                            </div>

                            <button onClick={() => setShowPaymentMethodView(true)} className="dropdown-item">
                                <p className="dropdown-title">
                                    <span className="">Payment Methods</span>
                                </p>
                                {/* <span className="dropdown-subtitle">credit</span> */}
                            </button>
                            <div className="dropdown-item">
                                <span className="dropdown-title">{authUser?.email}</span>
                                <span className="dropdown-subtitle">email</span>
                            </div>

                            <div className="dropdown-item">
                                <span className="dropdown-title disabled">Email OTP</span>
                                <span className="dropdown-subtitle">Security</span>
                            </div>
                            <div onClick={logoutUser} className="dropdown-item">
                                <span className="dropdown-title">Logout</span>
                            </div>
                            <div className="dropdown-footer">
                                <p className="footer-text mb-3">Terms.</p>
                                <p className="footer-subtext">neoai 0.0.1,</p>
                                <p className="footer-subtext">&copy;2023. Neoworlder. Inc.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {/*    chart*/}
            <CreditsModal isOpen={showCreditsModal} onClose={() => setShowCreditsModal(false)} />

            <ProfileModal isOpen={showProfileModal} onClose={() => setShowProfileModal(false)} />
            {/* Payment method view */}
            <PaymentMethodsModal isOpen={showPaymentMethodView} onClose={() => setShowPaymentMethodView(false)} />
            {/* Switch backends modal */}
            <NavigateBackendModal
                isOpen={showOrganizationModal}
                authUserToken={authUser?.token}
                onClose={() => setShowOrganizationModal(false)}
            />
        </div>
    );
};

function DropDownForm(props: { title: string; label: string }) {
    const [editMode, setEditMode] = useState<boolean>(false);
    const [titleForm, setTitleForm] = useState<string>("");
    const toast = useToast();

    const openEditForm = () => {
        setTitleForm(props.title);
        setEditMode(true);
    };

    const onSave = () => {
        if (!titleForm) {
            toast({
                title: "Please enter a title",
                status: "warning",
            });
            return;
        }

        setEditMode(false);
    };

    return editMode ? (
        <div className="accordion-edit-form">
            <div>
                <input value={titleForm} onChange={(e) => setTitleForm(e.target.value)} type="text" />
            </div>
            <div className="actions pl-2">
                <button className="save" onClick={onSave}>
                    <AiOutlineCheck className="icon" />
                </button>
                <button className="text-red-500">
                    <AiOutlineClose onClick={() => setEditMode(false)} className="icon" />
                </button>
            </div>
        </div>
    ) : (
        <div className="user-data-white-form">
            <div className="dropdown-item">
                <span className="dropdown-title">{props.title}</span>
                <span className="dropdown-subtitle">{props.label}</span>
            </div>
            <div className="actions">
                <button onClick={openEditForm}>
                    <AiOutlineEdit className="icon" />
                </button>
            </div>
        </div>
    );
}

export default ProfileDropdown;
