import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {} from "@stripe/stripe-js";
import { SubscriptionModel } from "../../models/stripe.model";
export interface AccountApiKeys {
    openAi: {
        apiKey: string;
    };
    pinecone: {
        key: string;
        env: string;
        index: string;
    };
}
export interface AccountData {
    id: string;
    email: string;
    name: string;
    username: string;
    createdAt?: number;
    updatedAt?: number;
    stripe_customer_id?: string;
    default_memory_created?: string;
    rlhf_encryption_key?: string;
    uui_subscription_status?: "active" | "expired" | "processing" | "inactive";
    backend_subscription_status?: "active" | "expired" | "processing" | "inactive";
}

declare type SubscriptionStatus = "not_fetching" | "loading" | "fetched_error" | "fetched";

export interface AccountState {
    isAccountLoading: boolean;
    // apiKeys: AccountApiKeys;
    accountExists: boolean;
    data: AccountData;
    subscription: SubscriptionModel | null;
    subscriptionStatus: SubscriptionStatus;
    creditBalance: number;
}

const initialState: AccountState = {
    isAccountLoading: true,
    accountExists: false,
    data: {
        id: "",
        email: "",
        name: "",
        username: "",
    },
    subscription: null,
    subscriptionStatus: "not_fetching",
    creditBalance: 0
};

const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setUserAccountLoading(state, action: PayloadAction<boolean>) {
            state.isAccountLoading = action.payload;
        },
        setUserAccountExists(state, action: PayloadAction<boolean>) {
            state.accountExists = action.payload;
        },
        setAccountCredentials(state, actions: PayloadAction<AccountData>) {
            state.data = actions.payload;
        },
        setAccountSubscriptionData(state, actions: PayloadAction<SubscriptionModel>) {
            state.subscription = actions.payload;
        },
        clearSubscriptionData(state) {
            state.subscription = null;
        },
        setSubscriptionStatus(state, actions: PayloadAction<SubscriptionStatus>) {
            state.subscriptionStatus = actions.payload;
        },
        setUserCreditBalance(state, actions: PayloadAction<number>) {
            state.creditBalance = actions.payload;
        }
    },
});
export const {
    setAccountCredentials,
    setUserAccountExists,
    setUserAccountLoading,
    setAccountSubscriptionData,
    clearSubscriptionData,
    setSubscriptionStatus,
    setUserCreditBalance
} = accountSlice.actions;
export default accountSlice.reducer;
