import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import WaitingRoom from "../views/WaitingRoom";
import RoomsCanvasView from "../views/RoomsCanvasView";
import ThreadView from "../views/ThreadView";
import OAuthCallback from "../views/auth/OAuthCallback";
import RoomView from "../views/RoomView";
import SubscriptionFailed from "../views/payments/callback/SuscriptionFailed";
import SubscriptionSuccess from "../views/payments/callback/SubscriptionSuccess";
import PaywallView from "../views/payments/PaywallView";
import ProtectedRoute from "../components/auth/ProtectedRoute";
import RedirectAuth from "../components/auth/RedirectAuth";
import OpenPersonaPortalView from "../views/OpenPersonaPortalView";

export enum ROUTES {
    ROOT = "/",
    OAUTH_CALLBACK = "/oauth_callback",
    WAITING_ROOM = "/waiting-room/:id",
    THREAD_VIEW = "/waiting-room/thread/:id",
    ROOM_CHAT = "/room/:id",
    ROOM_GROUP_CHAT = "/room-group/:id",
    ROOM_CHAT_THREAD = "/room/:id/thread/:messageId",
    ROOM_GROUP_THREAD = "/room-group/:id/thread/:messageId",
    SUBSCRIPTION_SUCCESS_CALLBACK = "/payments/callback/subscription-success",
    SUBSCRIPTION_CANCELLED_CALLBACK = "/payments/callback/subscription-cancelled",
    CREDIT_PURCHASE_CALLBACK = "/payments/callback/credit-purchase",
    PAYWALL = "/payments/paywall",
    OPEN_PERSONA_PORTAL = "/open-portal",
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                path: ROUTES.ROOT,
                element: (
                    <ProtectedRoute>
                        <RoomsCanvasView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.WAITING_ROOM,
                element: (
                    <ProtectedRoute>
                        <WaitingRoom />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.ROOM_CHAT_THREAD,
                element: (
                    <ProtectedRoute>
                        <ThreadView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.ROOM_GROUP_THREAD,
                element: (
                    <ProtectedRoute>
                        <ThreadView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.OAUTH_CALLBACK,
                element: (
                    <RedirectAuth>
                        <OAuthCallback />
                    </RedirectAuth>
                ),
            },
            {
                path: ROUTES.ROOM_CHAT,
                element: (
                    <ProtectedRoute>
                        <RoomView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.ROOM_GROUP_CHAT,
                element: (
                    <ProtectedRoute>
                        <RoomView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.SUBSCRIPTION_SUCCESS_CALLBACK,
                element: <SubscriptionSuccess />,
            },
            {
                path: ROUTES.SUBSCRIPTION_CANCELLED_CALLBACK,
                element: <SubscriptionFailed />,
            },
            {
                path: ROUTES.PAYWALL,
                element: (
                    <ProtectedRoute>
                        <PaywallView />
                    </ProtectedRoute>
                ),
            },
            {
                path: ROUTES.OPEN_PERSONA_PORTAL,
                element: (
                    <ProtectedRoute>
                        <OpenPersonaPortalView />
                    </ProtectedRoute>
                ),
            },
        ],
    },
]);

export default router;
