import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileObject } from "../../models/room.model";
import { addMessage, RoomMessageWithLoading } from "../slices/message.slice";
import { addThreadMessage } from "./threads.slice";

export interface RoomsState {
    isFetching: boolean;
    activeRoomId: string;
    activeRoomFileList: FileObject[];
    watchingCollabIdList: string[];
    data: any[];
}

const initialState: RoomsState = {
    isFetching: false,
    activeRoomId: "",
    activeRoomFileList: [],
    watchingCollabIdList: [],
    data: [],
};

const roomsSlice = createSlice({
    name: "rooms",
    initialState,
    reducers: {
        setIsRoomsFetching(state, payload: PayloadAction<boolean>) {
            state.isFetching = payload.payload;
        },
        setRoomsState(state, payload: PayloadAction<any>) {
            state.data = payload.payload || [];
        },
        removeRoomFromState: (state, action: PayloadAction<string>) => {
            state.data = state.data.filter((room) => room.id !== action.payload);
        },
        setActiveRoomId(state, payload: PayloadAction<string>) {
            state.activeRoomId = payload.payload;
        },
        setWatchingCollabIdList(state, payload: PayloadAction<string[]>) {
            state.watchingCollabIdList = Array.from(new Set(payload.payload));
        },
        addWatchingCollabId(state, payload: PayloadAction<string>) {
            const idList = [...state.watchingCollabIdList];

            if (!idList.includes(payload.payload)) {
                idList.push(payload.payload);
            }

            state.watchingCollabIdList = idList;
        },
        setActiveRoomFileList(state, payload: PayloadAction<FileObject[]>) {
            state.activeRoomFileList = payload.payload;
        },
        addFileToActiveRoomFileList(state, payload: PayloadAction<FileObject>) {
            if (!state.activeRoomFileList.find(file => file.id === payload.payload.id)) {
                state.activeRoomFileList = [...state.activeRoomFileList, payload.payload];
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(addMessage, (state, action: PayloadAction<RoomMessageWithLoading>) => {
            if ((action.payload.message.file_object as FileObject)?.id) {
                if (!state.activeRoomFileList.find(file => file.id === (action.payload.message.file_object as FileObject).id)) {
                    state.activeRoomFileList = [...state.activeRoomFileList, action.payload.message.file_object as FileObject];

                    console.log(state.activeRoomFileList);
                }
            }
        }).addCase(addThreadMessage, (state, action: PayloadAction<RoomMessageWithLoading>) => {
            if ((action.payload.message.file_object as FileObject)?.id) {
                if (!state.activeRoomFileList.find(file => file.id === (action.payload.message.file_object as FileObject).id)) {
                    state.activeRoomFileList = [...state.activeRoomFileList, action.payload.message.file_object as FileObject];
                }
            }
        });
    }
});

export const selectActiveRoomId = (state: { rooms: RoomsState }) => state.rooms.activeRoomId;
export const selectWatchingCollabIdList = (state: { rooms: RoomsState }) => state.rooms.watchingCollabIdList;
export const selectActiveRoomFileList = (state: {rooms: RoomsState}) => state.rooms.activeRoomFileList;

export const { setRoomsState, setIsRoomsFetching, removeRoomFromState, setActiveRoomId, setWatchingCollabIdList, addWatchingCollabId, setActiveRoomFileList, addFileToActiveRoomFileList } = roomsSlice.actions;

export default roomsSlice.reducer;
