import React, { useEffect, useRef } from "react";
import { Socket } from "socket.io-client";
import { Outlet, useNavigate } from "react-router-dom";
import { getAuthUser } from "./utils/auth.utils";
import { useHttpClient } from "./utils/http.utils";
import { useDispatch, useSelector } from "react-redux";
import { AuthState, setAuthState, setAuthUser } from "./store/slices/auth.slice";
import { AccountState } from "./store/slices/account.slice";
import { BrainScanState } from "./store/slices/brain-scan.slice";
import hotToast from "react-hot-toast";
import { ROUTES } from "./routes";
import ErrorBoundary from "./views/ErrorBoundary";

function App() {
    const authUser: AuthState = useSelector((state: any) => state.auth);
    const brainScanState: BrainScanState = useSelector((state: any) => state.brainScan);
    const socketRef = useRef<Socket | null>(null);
    const {
        listPersonasApi,
        listRoomsApi,
        // listGroupApi,
        fetchUserCredentialsApi,
        listPaymentMethodApi,
        listUserSubscriptionApi,
        // list credit balance
        listCreditBalanceApi
    } = useHttpClient();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const accountState: AccountState = useSelector((state: any) => state.account);
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);

    useEffect(() => {
        const authUserData = getAuthUser();
        if (authUserData.token && !authUser.isLoggedIn) {
            dispatch(setAuthState(true));
            dispatch(setAuthUser(authUserData));
        }
        /**
         * Fetch other data data
         */
        if (authUser.user?.token) {
            /**
             * This hook is used to add api calls to fetch data that will used during the lift cyle of the app
             * 1. Fetch user's subscription data..
             */
            // fetch account data
            if (!accountState.data.id && authUser.user?.email) {
                fetchUserCredentialsApi(authUserData.email);
            }

            if (!accountState.subscription) {
                if (accountState.data.stripe_customer_id) {
                    listUserSubscriptionApi().then((data) => {
                        if (data?.length === 0) {
                            navigate(ROUTES.PAYWALL);
                        }
                    });
                }

            }

            /**
             * List credit balance
             */
            listCreditBalanceApi();

            listPersonasApi();

            listRoomsApi({
                populate: {
                    owner: true,
                },
            });

            // listGroupApi();
            if (paymentMethodState.data.length === 0) {
                listPaymentMethodApi().catch((err) => {
                    hotToast.error("Cannot fetch your payment methods at this time, Please try again later");
                });
            }
        }
    }, [authUser.user]);

    /**
     * Socket listener hook, this hook runs every time the brain activity state changes
     */
    // useEffect(() => {
    //     if (!socketRef.current) {
    //         socketRef.current = initSocket();
    //     }

    //     if (
    //         brainScanState.isBrainScanInView &&
    //         brainScanState.socketRoom
    //     ) {
    //         // connect to sockets
    //         socketRef.current.connect();
    //     } else {
    //         socketRef.current.disconnect();
    //     }
    //     /**
    //      * When the socket connects join the channel
    //      */
    //     function onConnect() {
    //         console.log("connected to the socket"); //debug

    //         dispatch(setSocketConnected(true));
    //     }

    //     function onDisconnect() {
    //         console.log("disconnected to the socket"); //debug

    //         dispatch(setSocketConnected(false));
    //     }

    //     socketRef.current.on("connect", onConnect);
    //     socketRef.current.on("disconnect", onDisconnect);
    //     socketRef.current.on("connect_error", console.log);

    //     if (brainScanState.socketRoom) {
    //         socketRef.current.on(
    //             brainScanState.socketRoom,
    //             ({ data }: { data: BrainScanSequenceMessage }) => {
    //                 // if message is an activity message
    //                 if (
    //                     data.message_type === "ai" &&
    //                     (data.thought ||
    //                         data.action ||
    //                         data.observation ||
    //                         data.content_log ||
    //                         data.evaluation ||
    //                         data.tool_result)
    //                 ) {
    //                     dispatch(addSequenceMessage({
    //                         ...data,
    //                         collaboration_app_id: brainScanState.socketRoom.replace("collaboration-app-", ""),
    //                     }),
    //                 );
    //             }
    //         });
    //     }

    //     // /**
    //     //  *
    //     //  * Connect to specific interaction room
    //     //  */
    //     // if (collaborateState.autoInteractionId) {
    //     //     console.log(
    //     //         `listening to auto-interaction-update-${collaborateState.autoInteractionId}`,
    //     //     ); //debug

    //     //     socket.on(
    //     //         `auto-interaction-update-${collaborateState.autoInteractionId}`,
    //     //         autoInteractionUpdateHandler,
    //     //     );
    //     // }

    //     return () => {
    //         socketRef.current.off("connect", onConnect);
    //         socketRef.current.off("disconnect", onDisconnect);
    //         // socket.on(
    //         //     `auto-interaction-update-${collaborateState.autoInteractionId}`,
    //         //     autoInteractionUpdateHandler,
    //         // );
    //     };
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [brainScanState.isBrainScanInView, brainScanState.socketRoom]);

    return (
        <ErrorBoundary>
            <div id="App">
                <Outlet />
            </div>
        </ErrorBoundary>
    );
}

export default App;
