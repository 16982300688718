import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    useDisclosure,
    Button,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentMethodModel } from "../models/payment.model";
import { useHttpClient } from "../utils/http.utils";
import { FiPlus } from "react-icons/fi";
import { useSelector } from "react-redux";
import { DeleteConfirmation } from "./utils/ConfirmationModals";
import hotToast from "react-hot-toast";
import PaymentMethodForm from "./payment/PaymentMethodForm";
import PaymentMethodTile from "./payment/PaymentMethodTile";

interface PaymentModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

const PaymentMethodModal: React.FC<PaymentModalProps> = (props) => {
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const { updatePaymentMethodApi, deletePaymentMethodApi } = useHttpClient();
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;

    useEffect(() => {
        if (props.isOpen) {
            viewDis.onOpen();
        } else {
            viewDis.onClose();
        }
    }, [props.isOpen]);

    const closeFormModal = () => {
        setIsAddFormOpen(false);
    };

    const closeViewModal = () => {
        viewDis.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const deletePaymentMethod = (paymentMethod: PaymentMethodModel) => {
        DeleteConfirmation({
            title: "Delete payment method",
            message: `Are you sure you want to remove the card ending with ${paymentMethod.card_extract} ?`,
        }).then((isConfirmed) => {
            if (isConfirmed) {
                // show loading
                hotToast.promise(deletePaymentMethodApi(paymentMethod.id), {
                    loading: "Deleting Payment method...",
                    success: "Payment method deleted",
                    error: "Cannot delete payment method",
                });
            }
        });
    };

    const setPaymentMethodAsDefault = (paymentMethod: PaymentMethodModel) => {
        // show loading
        hotToast.promise(updatePaymentMethodApi(paymentMethod.id, { is_default: "yes" }), {
            loading: "Setting to default...",
            success: "The Payment method has been set to default",
            error: "Cannot update payment method",
        });
    };
    const viewDis = useDisclosure();

    return (
        <>
            <Modal size="2xl" trapFocus={false} isOpen={viewDis.isOpen} onClose={closeViewModal}>
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton />
                    <ModalHeader>
                        <div className="border-b pr-[32px]">
                            <div className="mb-[5px]">
                                <h4 className="text-[15px] font-[400] mb-[7px] leading-[1]">Payment Methods</h4>
                                <p className="text-[13px] font-[400] leading-[1] mb-0 text-gray-500">
                                    Manage your payment methods
                                </p>
                            </div>
                            <div className="actions mt-3 mb-3">
                                <Button
                                    onClick={() => setIsAddFormOpen(true)}
                                    size="sm"
                                    leftIcon={<FiPlus />}
                                    colorScheme="brand"
                                >
                                    Add
                                </Button>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody pt={0}>
                        <div className="w-full min-h-[500px]">
                            <div className="body">
                                {paymentMethods.map((paymentMethod) => (
                                    <PaymentMethodTile
                                        key={`pm_${paymentMethod.id}`}
                                        data={paymentMethod}
                                        onDelete={() => deletePaymentMethod(paymentMethod)}
                                        onSetAsDefault={() => setPaymentMethodAsDefault(paymentMethod)}
                                    />
                                ))}
                            </div>
                            <Elements stripe={stripePromise}>
                                <ElementsConsumer>
                                    {({ elements, stripe }) => (
                                        <PaymentMethodForm isOpen={isAddFormOpen} onClose={closeFormModal} />
                                    )}
                                </ElementsConsumer>
                            </Elements>
                        </div>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default PaymentMethodModal;
