import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PersonaModel } from "../../models/persona.model";

export interface PersonaState {
    loading: boolean;
    personas: PersonaModel[];
}

const initialState: PersonaState = {
    loading: false,
    personas: [],
};

const personaSlice = createSlice({
    name: "persona",
    initialState,
    reducers: {
        setPersonaLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },
        populatePersonas: (state, action: PayloadAction<PersonaModel[]>) => {
            // Remove personas with empty names
            // const personas = action.payload.filter(
            //     (persona: PersonaModel) => !!persona.name,
            // );
            state.personas = [...action.payload];
        },
        addPersona: (state, action: PayloadAction<PersonaModel>) => {
            state.personas.push(action.payload);
        },
        updatePersona: (state, action: PayloadAction<PersonaModel>) => {
            const index = state.personas.findIndex((persona) => persona.id === action.payload.id);
            if (index > -1) {
                state.personas[index] = action.payload;
            }
        },
        removePersona: (state, action: PayloadAction<string>) => {
            const index = state.personas.findIndex((persona) => persona.id === action.payload);
            if (index > -1) {
                state.personas.splice(index, 1);
            }
        },
    },
});

// Export actions
export const { setPersonaLoading, populatePersonas, addPersona, updatePersona, removePersona } = personaSlice.actions;

export default personaSlice.reducer;
