interface MetaTags {
    title: string;
    description?: string;
    imageUrl?: string;
    disableTrailAppName?: boolean;
}
export function setMetaTags(meta: MetaTags) {
    document.title = `${meta.title} ${
        !meta.disableTrailAppName ? "| NeoAI" : ""
    }`;
}
