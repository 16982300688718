import "../styles/ControlGroupNotifications.component.scss";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

interface ControlGroupNotificationsProps {
    title: string;
    subtitle: string;
    secondSubtitle?: any;
    hideAcceptButton?: boolean;
    unread?: boolean;
}
export function ControlGroupNotifications(
    props: ControlGroupNotificationsProps,
) {
    return (
        <div
            className={`control-group-notification flex justify-between ${
                props.unread && "unread"
            } items-center`}
        >
            <div className="leading">
                <div className="title">{props.title}</div>
                <div className="subtitle">{props.subtitle}</div>
                {<div className="subtitle">{props.secondSubtitle}</div>}
            </div>
            <div className="action flex">
                {!props.hideAcceptButton && (
                    <button className="accept-btn btn">
                        <AiOutlineCheck className="icon" />
                    </button>
                )}
                <button className="reject-btn btn">
                    <AiOutlineClose className="icon" />
                </button>
            </div>
        </div>
    );
}
