import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import router from "./routes";
import { RouterProvider } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { Provider } from "react-redux";
import store from "./store";
import { Toaster } from "react-hot-toast";

const theme = extendTheme({
    colors: {
        brand: {
            100: "#f7fafc",
            200: "#f7fafc",
            300: "#1a202c",
            400: "#1a202c",
            500: "#1a202c",
            600: "#1a202c",
            700: "#1a202c",
            800: "#1a202c",
            900: "#1a202c",
        },
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <ChakraProvider theme={theme}>
                <RouterProvider router={router} />
            </ChakraProvider>
            <Toaster
                position="bottom-center"
                toastOptions={{
                    // Define default options
                    className: "bg-black text-white",
                    duration: 5000,
                    style: {
                        background: "#363636",
                        color: "#fff",
                    },
                }}
            />
        </Provider>
        {/*<div className="app_support-warning">*/}
        {/*    <div className="text-center">*/}
        {/*        <img src="/neo_ai_logo_black.png" alt="" className="app_logo"/>*/}
        {/*        <p className="text-slate-500 text-sm mt-6">*/}
        {/*            This application is currently not supported on Mobile/Tablet. Please switch to a desktop.*/}
        {/*        </p>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/* <App /> */}
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
