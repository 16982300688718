import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalCloseButton,
    ModalHeader,
    ModalBody,
    Button,
    FormControl,
    FormLabel,
    Input,
    FormErrorMessage,
    ModalFooter,
    ButtonGroup,
    useDisclosure,
} from "@chakra-ui/react";
import { Formik, Field, FormikProps } from "formik";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Form, useNavigate } from "react-router-dom";
import { AuthState } from "../../store/slices/auth.slice";
import { useHttpClient } from "../../utils/http.utils";
import { FiCheck } from "react-icons/fi";
import hotToast from "react-hot-toast";
import { PaymentMethodModel } from "../../models/payment.model";
import { AccountState } from "../../store/slices/account.slice";
import { formatDate } from "../../utils/date.utils";
import { DeleteConfirmation } from "../utils/ConfirmationModals";
import { ROUTES } from "../../routes";

interface ProfileModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const ProfileModal: React.FC<ProfileModalProps> = (props) => {
    const {
        updateUserDetailsApi,
        updateUserProfilePictureApi,
        listSubscriptionPricesApi,
        createSubscriptionSessionApi,
        cancelUserSubscriptionApi,
    } = useHttpClient();
    const avatarDis = useDisclosure();
    const inputFile = useRef(null);
    const avatarInputControl = useRef(null);
    const [subscriptionPrices, setSubscriptionPrices] = useState<any[]>([]);
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(false);
    const authState: AuthState = useSelector((state: any) => state.auth);
    const { user: authUser } = authState;
    const formikRef = useRef<FormikProps<{
        name: string | undefined;
        username: string | undefined;
    }> | null>(null);
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);
    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;
    const accountState: AccountState = useSelector((state: any) => state.account);
    const navigate = useNavigate();

    useEffect(() => {
        if (props.isOpen) {
            avatarDis.onOpen();
        } else {
            avatarDis.onClose();
        }
    }, [props.isOpen]);

    useEffect(() => {
        const fetchSubscriptionPrices = async () => {
            const data = await listSubscriptionPricesApi();
            if (Array.isArray(data)) {
                setSubscriptionPrices(data);
            }
        };

        if (subscriptionPrices.length === 0) {
            fetchSubscriptionPrices();
        }
    }, []);

    const createSubscriptionSession = () => {
        /**
         * If the user has not configured payment method,
         * return and ask them to add a payment method
         */
        if (paymentMethods.length < 1) {
            hotToast.error("Please add a payment method first");
            return;
        }

        if (subscriptionPrices.length > 0) {
            setIsLoadingSubscription(true);

            createSubscriptionSessionApi({
                subscription_price: subscriptionPrices[0].id,
            })
                .then((res) => {
                    if (res?.session_url) {
                        window.location.href = res.session_url;
                    }
                })
                .catch(() => {
                    setIsLoadingSubscription(false);
                });
        }
    };

    const openFilePicker = () => {
        (inputFile.current as any).click();
    };

    const closeViewModal = () => {
        avatarDis.onClose();
        if (typeof props.onClose === "function") {
            props.onClose();
        }
    };

    const updateUserDetails = async (value) => {
        await updateUserDetailsApi({
            name: value.name,
            username: value.username,
        });
        // close modal
        closeViewModal();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (!["image/jpeg", "image/png"].includes(file.type)) {
                // Inform user about invalid file type
                return;
            }
            // update  in file
            (avatarInputControl.current as any).src = URL.createObjectURL(file);
            // update in api
            updateUserProfilePictureApi(file);
        }
    };

    function validateRequired(key, value) {
        let error;

        if (!value) {
            error = `${key} is required`;
        }
        return error;
    }

    const cancelSubscription = () => {
        DeleteConfirmation({
            title: "Confirm Cancellation",
            message: `Are you sure you want to cancel your subscription?`,
            keyword: "Cancel",
        }).then((isConfirmed) => {
            if (isConfirmed) {
                // show loading
                hotToast.promise(cancelUserSubscriptionApi(accountState.subscription?.id as string), {
                    loading: "Cancelling subscription...",
                    success: () => {
                        navigate(ROUTES.PAYWALL);
                        return "Subscription cancelled"
                    },
                    error: "Cannot cancel subscription",
                });
            }
        });
    };

    return (
        <Modal size="xl" trapFocus={false} isOpen={avatarDis.isOpen} onClose={closeViewModal}>
            <ModalOverlay />
            <ModalContent>
                <ModalCloseButton />
                <ModalHeader>Profile Details</ModalHeader>
                <ModalBody>
                    <div className="flex justify-center items-center flex-col">
                        <input
                            ref={inputFile}
                            type="file"
                            accept="image/jpeg, image/png"
                            style={{ display: "none" }}
                            onChange={handleFileChange}
                        />
                        <img
                            ref={avatarInputControl}
                            className="h-[120px] w-[120px] rounded-full"
                            src={authUser?.profile_picture}
                            onError={(error) => {
                                (avatarInputControl.current as any).src = "/user_avatar.png";
                            }}
                            alt="profile"
                        />
                        {/* <img
                        className="h-24 w-24 rounded-full"
                        src={authUser?.profile_picture}
                    /> */}
                        <div className="flex items-center mt-3 mb-4">
                            <Button onClick={openFilePicker} variant="ghost" size="sm" colorScheme="blue">
                                Change
                            </Button>
                            {/* <Button
                            variant="ghost"
                            size="sm"
                            colorScheme="red"
                        >
                            Delete
                        </Button> */}
                        </div>
                    </div>
                    {(accountState.data.uui_subscription_status === "active" || accountState.data.backend_subscription_status === "active") ? (
                        accountState.subscription && (<div className="pt-4 mb-6">
                            <div className="bg-gray-100 px-4 py-4 min-h-[70px] rounded-[12px] border border-gray-200">
                                <div className="">
                                    <h2 className="mb-[4px]">Your plan</h2>
                                    <div className="flex justify-between">
                                        <div className="">
                                            <p className="price text-[20px] text-blue-600">
                                                <span className="">
                                                    ${(Number(accountState.subscription?.plan.amount) * Number(accountState.subscription?.quantity)) / 100 || "10"}
                                                </span>
                                                <span className="">/{accountState.subscription.plan.interval}</span>
                                            </p>
                                            <p className="text-[13px] text-slate-500">
                                                Subscribed on:{" "}
                                                {formatDate(
                                                    Number(accountState.subscription?.current_period_start) * 1000,
                                                    "LL",
                                                )}
                                            </p>
                                            <p className="text-[13px] text-slate-500">
                                                Next subscription date:{" "}
                                                {formatDate(
                                                    Number(accountState.subscription?.current_period_end) * 1000,
                                                    "LL",
                                                )}
                                            </p>
                                        </div>

                                        <div className="flex items-center">
                                            <Button
                                                onClick={cancelSubscription}
                                                variant={"ghost"}
                                                colorScheme="red"
                                                size="sm"
                                            >
                                                Cancel
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    ) : (
                        <div className="pt-4 mb-6">
                            <div className="bg-gradient-to-r from-indigo-900 via-blue-900 to-blue-800 px-6 py-6 min-h-[70px] rounded-[12px] text-white border border-gray-300">
                                <div className="">
                                    <h2 className="mb-[5px]">Upgrade to a paid plan</h2>
                                    {subscriptionPrices.length > 0 && (
                                        <div className="flex justify-between">
                                            <p className="price mb-2 font-thin">
                                                <span className="text-[26px]">
                                                    ${subscriptionPrices[0]?.unit_amount / 100 || "10"}
                                                </span>
                                                <span className="text-gray-200">
                                                    /${subscriptionPrices[0]?.recurring.interval}
                                                </span>
                                            </p>
                                            <div className="pr-2 flex items-center">
                                                <Button
                                                    isDisabled={subscriptionPrices.length < 1}
                                                    backgroundColor={"#fff"}
                                                    color="#1e1e1e"
                                                    isLoading={isLoadingSubscription}
                                                    onClick={createSubscriptionSession}
                                                >
                                                    Subscribe
                                                </Button>
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex gap-2 items-center mb-2">
                                        <FiCheck size="16px" />
                                        <p className="text-[12px]">
                                            Interact with all synthetic life forms or arins without invitation
                                        </p>
                                    </div>
                                    <div className="flex gap-2 items-center">
                                        <FiCheck size="16px" />
                                        <p className="text-[12px]">
                                            Create and license Personas and other synthetic life forms
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <Formik
                        initialValues={{
                            name: authUser?.fullname,
                            username: authUser?.username,
                        }}
                        onSubmit={updateUserDetails}
                    >
                        {(formikProps) => {
                            formikRef.current = formikProps;
                            return (
                                <Form>
                                    <Field name="name" validate={(e) => validateRequired("Name", e)}>
                                        {({ field, form }) => (
                                            <FormControl mb={6} isInvalid={form.errors.name && form.touched.name}>
                                                <FormLabel>Name</FormLabel>
                                                <Input {...field} placeholder="Name" />
                                                <FormErrorMessage>{form.errors.name}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>

                                    <Field name="username" validate={(e) => validateRequired("Username", e)}>
                                        {({ field, form }) => (
                                            <FormControl
                                                mb={6}
                                                isInvalid={form.errors.username && form.touched.username}
                                            >
                                                <FormLabel>Username</FormLabel>
                                                <Input
                                                    {...field}
                                                    placeholder="Username"
                                                    isDisabled={true}
                                                    // disabled={
                                                    //     authUser?.username !==
                                                    //     ''
                                                    // }
                                                />
                                                <FormErrorMessage>{form.errors.username}</FormErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                </Form>
                            );
                        }}
                    </Formik>
                </ModalBody>
                <ModalFooter>
                    <ButtonGroup spacing={2}>
                        <Button
                            type="submit"
                            colorScheme="brand"
                            onClick={() => {
                                if (formikRef.current) {
                                    updateUserDetails(formikRef.current.values);
                                    formikRef.current.handleSubmit();
                                    avatarDis.onClose();
                                }
                            }}
                        >
                            Save
                        </Button>
                        <Button onClick={closeViewModal}>Close</Button>
                    </ButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default ProfileModal;
// http://localhost:10550/api/v1/payment-webhook/stripe-payment-webhook
