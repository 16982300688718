import "../styles/ControlGroupPersonaCard.component.scss";
import { BsFillStarFill, BsTag } from "react-icons/bs";
import { useEffect, useState } from "react";
import { BiCheckCircle } from "react-icons/bi";
import { useHttpClient } from "../utils/http.utils";
import toast from "react-hot-toast";

interface ControlGroupPersonaCardProps {
    personaId?: string;
    name: string;
    avatarImageUrl: string;
    type?: "arin" | "sylis";
    username: string;
    isVerified?: boolean;
    pricing?: string;
    rating?: number;
}

export default function ControlGroupPersonaCard(
    props: ControlGroupPersonaCardProps,
) {
    const [cardRating, setCardRating] = useState<string>("");
    const { createRoomApi } = useHttpClient();

    useEffect(() => {
        if (props.rating) {
            setCardRating(props.rating < 100 ? props.rating.toString() : "99+");
        }
    }, []);

    const createPersonaRoom = () => {
        if (props.personaId) {
            let createPromise = createRoomApi({
                title: props.name,
                description: `This field contains the description for ${props.name}`,
                persona_list: [props.personaId],
                is_single: "yes",
                status: "active",
            });

            toast.promise(createPromise, {
                loading: `Creating room with ${props.name}`,
                success: "Successfully created room",
                error: "Failed to create room",
            });
        }
    };

    return (
        <div
            onClick={createPersonaRoom}
            className="control-group flex justify-between items-center"
        >
            <div className="control-group-leading flex items-center gap-2">
                <div className="image">
                    <img
                        src={props.avatarImageUrl}
                        className="rounded-full"
                        alt=""
                    />
                </div>
                <div className="name-section">
                    <div className="title flex gap-1 items-end">
                        <h4 className="name">{props.name}</h4>
                        {props.type && (
                            <span className="type">{props.type}</span>
                        )}
                        {props.isVerified && (
                            <span className="badge">
                                <BiCheckCircle size={16} />
                            </span>
                        )}
                    </div>
                    <p className="username">{props.username}</p>
                </div>
            </div>
            <div className="control-group-trailing">
                {props.rating && (
                    <div className="stars flex mb-1 items-center gap-1">
                        <BsFillStarFill className="rating-icon" />
                        <BsFillStarFill className="rating-icon" />
                        <BsFillStarFill className="rating-icon" />
                        <BsFillStarFill className="rating-icon" />
                        <BsFillStarFill className="rating-icon" />
                        <span className="text">({cardRating})</span>
                    </div>
                )}
                {props.pricing && (
                    <div className="pricing flex items-center gap-1">
                        <BsTag className="pricing-icon" />
                        <span className="text">{props.pricing}</span>
                    </div>
                )}
            </div>
        </div>
    );
}
