import InfiniteCanvas from "../components/InfiniteCanvas";
import RoomCard from "../components/RoomCard";
import LayoutHeader from "../components/LayoutHeader";
import "../styles/Landing.component.scss";
import ControlGroup from "../components/ControlGroup";
import { useSelector } from "react-redux";
import { RoomsState } from "../store/slices/rooms.slice";
import { useEffect, useState } from "react";
import { useHttpClient } from "../utils/http.utils";
import { RoomGroupModel } from "../models/room.model";
import { setMetaTags } from "../utils/seo.utils";
// import { GroupsState } from "../store/slices/groups.slice";

export default function RoomsCanvasView() {
    const { listRoomsApi } = useHttpClient();
    const roomsState: RoomsState = useSelector((state: any) => state.rooms);
    // const groupsState: GroupsState = useSelector((state: any) => state.groups);
    const [rooms, setRooms] = useState<RoomGroupModel[]>([]);

    useEffect(() => {
        setMetaTags({title: "Interactive Canvas"});
    }, []);

    useEffect(() => {
        if (roomsState.data.length === 0) {
            // dispatch(fetchRooms());
        }
        if (roomsState.data?.length > 0) {
            setRooms(roomsState.data);
        }
    }, [roomsState.data]);

    return (
        <div className="canvas-container">
            <LayoutHeader />
            <InfiniteCanvas>
                <div
                    style={{
                        position: "relative",
                        marginLeft: "-15rem",
                    }}
                >
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            marginTop: "-14.7rem",
                            gap: "60px",
                        }}
                    >
                        <div className="flex gap-6 items-end">
                            {rooms.map((room, index) => {
                                const participants = room.persona_member_list.map((persona) => {
                                    return {
                                        name: persona.persona.first_name,
                                        role: "game warded",
                                        imageUrl: persona.persona.profile_picture || "/amanda.png",
                                        label: persona.persona.first_name,
                                        cost: "T$0.001/word",
                                    };
                                });
                                return (
                                    <RoomCard
                                        id={room.id}
                                        key={"room-display" + index}
                                        data={room}
                                        title={room.title}
                                        description={room.description}
                                        owner={room.owner.name || room.owner.username || room.owner.email}
                                        notificationCount={0}
                                        participants={participants}
                                        // participants={[
                                        //     {
                                        //         name: "Katy Ademeve",
                                        //         type: "sylis",
                                        //         role: "game warded",
                                        //         cost: "T$0.001/word",
                                        //         imageUrl: "/amanda.png",
                                        //     },
                                        // ]}
                                    />
                                );
                            })}

                            {/* {groupsState.data.map((group, index) => {
                                const participants =
                                    group.invitee_list.persona.map(
                                        (invitee) => {
                                            return {
                                                name: invitee.first_name,
                                                role: "game warded",
                                                imageUrl:
                                                    "https:xsgames.co/randomusers/assets/avatars/male/15.jpg",
                                                label: invitee.first_name,
                                                ...invitee,
                                            };
                                        },
                                    );

                                return (
                                    <RoomCard
                                        id={group.id}
                                        key={"group-display" + index}
                                        isGroup={true}
                                        title={group.title}
                                        description={group.description}
                                        owner={group.owner.display_name}
                                        roomHeader={{ title: "NeoWorlder" }}
                                        notificationCount={15}
                                        participants={participants}
                                        archivedColor={group.flag_color_list}
                                    />
                                );
                            })} */}
                        </div>
                    </div>
                </div>
            </InfiniteCanvas>
            <ControlGroup />
        </div>
    );
}
