import { Button, Skeleton, Stack } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { AiTwotoneCheckCircle } from "react-icons/ai";
import { useSelector } from "react-redux";
import { PaymentMethodModel } from "../../models/payment.model";
import { AccountState } from "../../store/slices/account.slice";
import { useHttpClient } from "../../utils/http.utils";
import hotToast from "react-hot-toast";
import { setMetaTags } from "../../utils/seo.utils";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, ElementsConsumer } from "@stripe/react-stripe-js";
import PaymentMethodForm from "../../components/payment/PaymentMethodForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK as string);

const PaywallView = () => {
    const { listSubscriptionPricesApi, createSubscriptionSessionApi } = useHttpClient();
    const paymentMethodState = useSelector((state: any) => state.paymentMethod);

    const [subscriptionPrices, setSubscriptionPrices] = useState<any[]>([]);
    const [isLoadingSubscriptionSession, setIsLoadingSubscriptionSession] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);

    const paymentMethods: PaymentMethodModel[] = paymentMethodState.data;
    const accountState: AccountState = useSelector((state: any) => state.account);

    useEffect(() => {
        setMetaTags({ title: "Subscription" });

        const fetchSubscriptionPrices = async () => {
            const data = await listSubscriptionPricesApi();
            if (Array.isArray(data)) {
                setSubscriptionPrices(data);
            }
        };

        if (subscriptionPrices.length === 0) {
            fetchSubscriptionPrices();
        }
    }, []);

    const createSubscriptionSession = () => {
        /**
         * If the user has not configured payment method,
         * return and ask them to add a payment method
         */
        if (paymentMethods.length < 1) {
            setIsAddFormOpen(true);
            return;
        }

        if (subscriptionPrices.length > 0) {
            setIsLoadingSubscriptionSession(true);

            createSubscriptionSessionApi({
                subscription_price: subscriptionPrices[0].id,
            })
                .then((res) => {
                    if (res?.session_url) {
                        window.location.href = res.session_url;
                    }
                })
                .catch(() => {
                    setIsLoadingSubscriptionSession(false);
                });
        }
    };

    const closeFormModal = () => {
        setIsAddFormOpen(false);
    };

    return (
        <div className="h-screen w-screen relative bg-gradient-to-br from-gray-50 via-gray-100 to-gray-300 flex flex-col justify-center items-center">
            <img
                src="/neo_ai_logo_black.png"
                alt=""
                className="h-[30px] mb-[16px] absolute top-[100px] right-[50%] translate-x-[50%]"
            />
            <div className="bg-white relative border shadow-sm rounded-[8px] h-[450px] w-[430px] px-5 py-4">
                <div className="border-b py-2">
                    <h2 className="text-[18px] font-semibold">Upgrade your Plan</h2>
                    <h4 className="text-[14px] text-slate-400">
                        You need an active subscription to have access to Neo UI
                    </h4>
                </div>
                <div className="">
                    {subscriptionPrices.length > 0 ? (
                        <div className="flex items-end py-[16px]">
                            <h2 className="text-[32px] leading-[1] font-semibold">
                                ${subscriptionPrices[0]?.unit_amount / 100}
                            </h2>
                            <p className="text-slate-600 mb-1 leading-[1]"> / month</p>
                        </div>
                    ) : (
                        <div className="flex items-end mb-6 pt-4">
                            <Stack>
                                <Skeleton height="20px" />
                            </Stack>
                        </div>
                    )}
                </div>
                <div className="border-t pt-4">
                    <div className="flex gap-2 items-center mb-2">
                        <AiTwotoneCheckCircle color="#3caf50" className="text-green-500" size="16px" />
                        <p className="text-[14px]">
                            Interact with all synthetic life forms or arins without invitation
                        </p>
                    </div>
                    <div className="flex gap-2 items-center">
                        <AiTwotoneCheckCircle size="16px" />
                        <p className="text-[14px]">Create and license Personas and other synthetic life forms</p>
                    </div>
                </div>
                <div className="absolute bottom-[18px] left-[15px] right-[15px]">
                    <Button
                        onClick={createSubscriptionSession}
                        colorScheme="brand"
                        className="w-full"
                        loadingText="Loading"
                        isLoading={isLoadingSubscriptionSession}
                    >
                        Subscribe
                    </Button>
                </div>
            </div>
            <Elements stripe={stripePromise}>
                <ElementsConsumer>
                    {({ elements, stripe }) => <PaymentMethodForm isOpen={isAddFormOpen} onClose={closeFormModal} />}
                </ElementsConsumer>
            </Elements>
        </div>
    );
};

export default PaywallView;
