import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RoomMessage } from "../../models/room.model";

export interface RoomMessageWithLoading {
    message: RoomMessage;
    loading: boolean;
    localID: string;
}

export interface RoomMessageThreadState {
    data: Array<RoomMessageWithLoading>;
}

const initialState: RoomMessageThreadState = {
    data: [],
};

const threadMessageSlice = createSlice({
    name: "thread_messages",
    initialState,
    reducers: {
        setThreadMessages(state, action: PayloadAction<RoomMessageWithLoading[]>) {
            state.data = action.payload;
        },
        addThreadMessage(state, action: PayloadAction<RoomMessageWithLoading>) {
            const existingMessage = state.data.find((msg) => msg.message.id === action.payload.message.id);
            if (!existingMessage) {
                state.data = [...state.data, action.payload];
            }
        },
        updateThreadMessageLoading(state, action: PayloadAction<{ id: string; loading: boolean }>) {
            state.data.map((msg) => (msg.message.id === action.payload.id ? { ...msg, loading: action.payload.loading } : msg));
        },

        removeThreadMessage: (state, action: PayloadAction<string>) => {
            state.data.filter((msg) => msg.message.id !== action.payload);
        },

        confirmThreadMessage(state, action: PayloadAction<{ localID: string; confirmedID: string }>) {
            state.data.map((msg) =>
                msg.localID === action.payload.localID
                    ? {
                        ...msg,
                        message: {
                            ...msg.message,
                            id: action.payload.confirmedID,
                        },
                        loading: false,
                    }
                    : msg,
            );
        },
    },
});

export const { addThreadMessage, updateThreadMessageLoading, setThreadMessages, confirmThreadMessage, removeThreadMessage } = threadMessageSlice.actions;
export default threadMessageSlice.reducer;
