import React, { useEffect, useRef, useState } from "react";
import "../styles/RoomCard.component.scss";
import { BsBriefcase, BsPatchCheckFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { ScaleContext } from "../utils/ScaleContext";
import Draggable from "react-draggable";
// import { GroupsState } from "../store/slices/groups.slice";
import { LordIcon } from "./icons/LordIcon";
import { ParticipantsModel, RoomGroupModel } from "../models/room.model";
import { Button, FormControl } from "@chakra-ui/react";
import Select from "react-select";
import { Rating } from "react-simple-star-rating";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import toast from "react-hot-toast";
import { useHttpClient } from "../utils/http.utils";
import { getRoomParticipantsUtil } from "../utils/room/room.utils";

interface IRoomCardType {
    id: string;
    data: RoomGroupModel;
    title: string;
    description: string;
    participants: ParticipantsModel[];
    owner?: string;
    notificationCount: number;
    archivedColor?: string;
    roomHeader?: {
        title: string;
    };
}

type UserPersonaType = { user?: string[]; persona?: string[] };

const RoomCard = (props: IRoomCardType) => {
    const navigate = useNavigate();
    const scale = React.useContext(ScaleContext);
    const [showSettings, setShowSettings] = useState(false);
    const containerRef = useRef(null);
    const descriptionRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const [selectedArins, setSelectedArins] = useState(props.participants);
    const [flagColor, setFlagColor] = useState(props.archivedColor || null);
    const [showCost, setShowCost] = useState(false);
    const [groupPeopleOptions, setGroupPeopleOptions] = useState<{ label: string; value: string }[]>([]);
    const { deleteRoomApi, searchInviteesApi, updateRoomApi } = useHttpClient();
    const [isRoomGroup, setIsRoomGroup] = useState<boolean>(false);

    const onSelectArins = (val) => {
        setSelectedArins(val);
    };

    const debouncedSearch = debounce(async (value) => {
        setIsLoading(true);

        try {
            const responseData = await searchInviteesApi(value);
            const inviteeOptions = responseData.map((invitee) => ({
                label: String(invitee.email),
                value: String(invitee.id),
                ...invitee,
            }));

            setGroupPeopleOptions([...inviteeOptions]);
        } catch (error) {
            console.error("Failed to fetch invitees:", error);
            toast.error("Failed to fetch invitees.");
        }

        setIsLoading(false);
    }, 300);

    const handleSearchInputChange = (value) => {
        if (!value) return;
        debouncedSearch(value);
    };

    useEffect(() => {
        const personaElements = (containerRef.current as unknown as HTMLDivElement).getElementsByClassName("persona-preview") as HTMLCollectionOf<HTMLDivElement>;
        const personaElementsList = Array.from(personaElements);
        const personaCount = personaElementsList.length > 4 ? 4 : personaElementsList.length;
        /**
         * if the room data has user_member_list and persona_member_list, set isRoomGroup true
         *
         */
        if (props.data.is_single !== "yes") {
            setIsRoomGroup(true);
        }
        /**
         * Set active participants
         */
        const participants = getRoomParticipantsUtil(props.data)
            .filter((particpant) => particpant.id !== props.data.owner?.id)
            .map((participant) => ({ label: participant.name, value: participant.id }));
        // chang eht
        setSelectedArins([{ value: props.data.owner.id, label: "Me" }, ...participants]);

        if (personaCount > 1) {
            personaElementsList.forEach((el) => {
                /**
                 * Set up events
                 * Add events if the persona is more than one
                 */
                el.style.width = 100 / personaCount + "%";
                el.addEventListener("mouseover", onPersonaHover);
                el.addEventListener("mouseout", (e) => {
                    personaElementsList.forEach((el) => (el.style.width = 100 / personaCount + "%"));
                    (el.getElementsByClassName("persona-overlay")[0] as HTMLDivElement).style.transitionDelay = "0s";
                    (el.getElementsByClassName("persona-overlay")[0] as HTMLDivElement).style.opacity = "0";
                });
            });
        } else if (personaCount === 1) {
            personaElementsList[0].style.width = "100%";
            (personaElementsList[0].getElementsByClassName("persona-overlay")[0] as HTMLDivElement).style.opacity = "1";
        }

        function onPersonaHover(e) {
            const currentElement = e.currentTarget;

            personaElementsList.forEach((el) => {
                let overlay = el.getElementsByClassName("persona-overlay")[0] as HTMLDivElement;
                if (el.className === currentElement.className) {
                    el.style.width = "60%";
                    overlay.style.transitionDelay = "0.2s";
                    overlay.style.opacity = "1";
                } else {
                    el.style.width = `${40 / (personaCount - 1)}%`;
                    overlay.style.transitionDelay = "0s";
                    overlay.style.opacity = "0";
                }
            });
        }
    }, []);

    const toggleExpand = () => {
        (descriptionRef.current as unknown as HTMLElement).classList.toggle("minimize");
    };

    const toggleCost = (cost) => {
        if (!cost) return;
        setShowCost(!showCost);
    };

    const KillEntity = () => {
        // if (isRoomGroup) {
        //     let promise = deleteGroupApi(props.id);

        //     toast.promise(promise, {
        //         loading: "Killing group...",
        //         success: "Group killed successfully",
        //         error: "Could not kill group",
        //     });
        // } else {
        // }
        let promise = deleteRoomApi(props.id);

        toast.promise(promise, {
            loading: "Killing room...",
            success: "Room killed successfully",
            error: "Could not kill room",
        });
    };

    const UpdateEntity = () => {
        if (isRoomGroup) {
            let groupedIds = selectedArins.reduce<UserPersonaType>((acc, item) => {
                if (item.type === "clone" || item.type === "sylis" || item.type === "arin") {
                    if (!acc.persona) acc.persona = [];
                    acc.persona.push(item.id as string);
                } else {
                    if (!acc.user) acc.user = [];
                    acc.user.push(item.id as string);
                }
                return acc;
            }, {});

            const userPersona: UserPersonaType[] = [];
            if (groupedIds.persona && groupedIds.persona.length) {
                userPersona.push({ user: groupedIds.user });
            }
            if (groupedIds.user && groupedIds.user.length) {
                userPersona.push({ user: groupedIds.user });
            }

            const requestBody = {
                id: props.id,
                invited_list: { user: groupedIds.user },
                invitee_list: { persona: groupedIds.persona },
                flag_color_list: flagColor,
            };
            let promise = updateRoomApi(requestBody);

            toast.promise(promise, {
                loading: "Updating group...",
                success: "Updated group successfully",
                error: "Could not update group",
            });
        } else {
            //update room
        }
    };

    return (
        <Draggable
            handle=".drag-handler"
            // onStart={console.log}
            // onDrag={console.log}
            // onStop={console.log}
        >
            <div ref={containerRef} style={{ "--scale": `${scale}` } as React.CSSProperties} className="room-card">
                {flagColor && (
                    <div className="attention-box">
                        <div className={`attention-banner ${flagColor}`}></div>
                    </div>
                )}
                <div className="card-header drag-handler">
                    {props.roomHeader && (
                        <div className="card-owner">
                            <img src="/NeoWorlder_Logo_black_Symbol_Only.png" alt="" />
                            <h2 className="card-owner-name">NeoWorlder</h2>
                        </div>
                    )}
                    <div className="card-header-content">
                        <div className="">
                            <div className="card-title">
                                {props.data.title}
                                {!isRoomGroup && <p className="arin-tag">{props.participants?.[0]?.type}</p>}
                            </div>
                            {props.data.owner && (
                                <p className="owner-text">
                                    by <span className="name">{props.data.owner.name || props.data.owner.username || props.data.owner.email}</span>.
                                </p>
                            )}
                            <p ref={descriptionRef} onClick={toggleExpand} className="card-description minimize">
                                {props.data.description}
                            </p>
                            {/*<div className="room-participants mt-2">*/}
                            {/*    <div className="pl-2">*/}
                            {/*        <BsFillPersonFill size={"18px"} />*/}
                            {/*    </div>*/}
                            {/*    <div className="persona-list">*/}
                            {/*        <p className="name w-full truncate">Carina (sylis), James, Jerry, +20 others</p>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                        </div>
                        {props.notificationCount > 0 && <p className="notification-badge">{props.notificationCount}</p>}
                    </div>
                </div>
                <div className="card-body">
                    {props.participants?.slice(0, 4).map((participant, index) => {
                        return (
                            <div
                                key={`participant-${index}`}
                                className={`persona-preview persona-${index}-card`}
                                style={{
                                    backgroundImage: `url(${participant.imageUrl})`,
                                }}
                            >
                                <div className="persona-overlay">
                                    {isRoomGroup && <h2 className="title">{participant.name}</h2>}
                                    {isRoomGroup && <h2 className="description">{participant.type}</h2>}
                                    {participant.role && (
                                        <div className="role-container">
                                            <div className="role-container-header" onClick={() => toggleCost(participant.cost)}>
                                                <h2 className="role">
                                                    <BsBriefcase size={"14px"} />
                                                    {participant.role}
                                                </h2>
                                                {participant.cost && <button>{showCost ? <FiChevronUp size={"14px"} /> : <FiChevronDown size={"14px"} />}</button>}
                                            </div>
                                            {participant.cost && showCost && (
                                                <div className="cost-container pt-2">
                                                    <p>
                                                        <span className="price">{participant.cost}</span>
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                </div>
                <div className="card-footer">
                    <div className="settings-container">
                        <button onClick={() => setShowSettings(!showSettings)} className="settings-btn">
                            <LordIcon
                                src="https://cdn.lordicon.com/mwzdatpw.json"
                                trigger="hover"
                                stroke={25}
                                colors={{
                                    primary: "#121331",
                                    secondary: "#333",
                                }}
                                size={35}
                            />
                        </button>

                        {showSettings && (
                            <div className={`settings-dropdown`}>
                                {isRoomGroup ? (
                                    <div className="mb-6">
                                        <div className="form-group">
                                            <div className="form-label-header">
                                                <p className="form-label">Manage People</p>
                                            </div>
                                            <FormControl mb={3}>
                                                <Select
                                                    defaultValue={selectedArins}
                                                    isMulti
                                                    isClearable={false}
                                                    name="colors"
                                                    options={groupPeopleOptions}
                                                    onChange={onSelectArins}
                                                    onInputChange={handleSearchInputChange}
                                                    placeholder={"Type to Add"}
                                                    className="arin-react-select-container"
                                                    classNamePrefix="arin-react-select"
                                                    loadingMessage={() => "Loading..."}
                                                    noOptionsMessage={() => (isLoading ? "Loading..." : "No options")}
                                                />
                                            </FormControl>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="rate-section flex justify-between items-center pt-2">
                                        {/* <div className="">
                                            <h2 className="title">Rate</h2>
                                            <h4 className="subtitle">{props.title}</h4>
                                        </div>
                                        <div className="form">
                                            <Rating size={20} />
                                        </div> */}
                                    </div>
                                )}
                                <div className="flag-form-section flex items-center">
                                    <div onClick={() => setFlagColor("red")} className={`color-input red ${flagColor === "red" ? "active" : ""}`}></div>
                                    <div onClick={() => setFlagColor("green")} className={`color-input green ${flagColor === "green" ? "active" : ""}`}></div>
                                    <div onClick={() => setFlagColor("blue")} className={`color-input blue ${flagColor === "blue" ? "active" : ""}`}></div>
                                    <div onClick={() => setFlagColor(null)} className={`color-input flex items-center ${!flagColor && "active"} justify-center no-color`}>
                                        <div className="cross"></div>
                                    </div>
                                </div>
                                <div className="flex justify-between w-full mt-4">
                                    <Button onClick={UpdateEntity} size="sm" style={{ fontWeight: 400 }} variant="ghost" colorScheme="green">
                                        Update
                                    </Button>
                                    <Button onClick={KillEntity} size="sm" style={{ fontWeight: 400 }} variant="ghost" colorScheme="red">
                                        Kill Portal
                                    </Button>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="settings-container">
                        <button onClick={() => navigate(isRoomGroup ? "/room-group/" + props.id : "/room/" + props.id)} className="settings-btn with-text">
                            <LordIcon
                                src="/lord-icons/chat-dots.json"
                                trigger="hover"
                                stroke={20}
                                colors={{
                                    primary: "#121331",
                                    secondary: "#333",
                                }}
                                size={45}
                            />
                            <span
                                style={{
                                    visibility: props.participants?.length > 1 ? "visible" : "hidden",
                                }}
                                className="text"
                            >
                                {props.participants?.length}
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};
export default RoomCard;
